.discussion-page-wrapper
  overflow: hidden
  display: flex
  flex-direction: row
  height: 100%
  overflow: auto
  -webkit-overflow-scrolling: touch
  flex: 1 1 auto
  height: 0

  .fluid-container.discussion-page
    position: relative
    height: auto
    box-shadow: none
    margin: 0

.fluid-container.discussion-page

  .discussion-message-selector
    padding: 16px 30px
    background: $card-background-color
    border: 1px solid $separator-color
    border-top: 0


  .scroll-to-latest-link
    width: 32px
    height: 32px
    position: absolute
    right: 0px
    bottom: 100px
    background: white
    z-index: 2
    cursor: pointer

    border-radius: 0.25rem 0 0 0.25rem
    box-shadow: -2px 2px 5px rgba(115, 131, 158, 0.15)

    .icon
      color: $icon-color
      position: absolute
      right: 4px
      top: 0px

      &::after
        color: $icon-color
        content: $icon-dropdown-arrow
        font:
          family: 'knowa-icons'
          size: 1.5rem

.discussion-list
  .card
    width: 100%
    margin-bottom: 1rem
    &:last-child
      margin-bottom: 0
  .card-title
    h5
      font-size: 1.2rem

.discussion-container
  background-color: transparent
  border: 0
  height: calc(100vh - #{$chat-box-height})
  overflow: auto
  -webkit-overflow-scrolling: touch
  display: flex
  flex-direction: column
  .container
    display: flex
    flex-flow: column-reverse nowrap
    flex: 1 0 auto
    height: auto !important
    padding-top: 1rem
    margin-top: auto
  @include media-breakpoint-down(sm)
    margin: .2rem 0
    padding: .25rem

  .date-separator
    @extend .badge
    border-radius: .25rem



.message-form
  margin:
    top: 1rem
    bottom: 1rem
  &.hidden
    margin:
      top: 0
      bottom: 0
  .form-field
    font-size: 15px

.discussion-description
  position: absolute
  width: 100%
  top: 100%
  left: 0
  z-index: 2
  @include media-breakpoint-down(md)
    display: none
  &-block
    @extend .message-block
    > p
      line-height: 1.4rem
  &-creds
    @extend .message-creds

.attach-document-link
  cursor: pointer
  display: block
  width: 25px
  height: 100%
  position: absolute
  top: 10%
  right: 10px
  transform: translateY(-50%)
  z-index: 100
  font-size: 26px
  color: $subheader-link-color
  display: flex
  align-items: center
  justify-content: center
  &.hidden
    display: none

.discussion-new-message-block
  display: none
  @include media-breakpoint-down(sm)
    display: none !important

.form-main-controls
  @extend .text-center
  @extend .mts
  // fix for buttons alignment in IE Edge
  display: flex
  justify-content: center

button
  &#desktop-new-message
    @include media-breakpoint-down(sm)
      display: none
  &#mobile-new-message, &#mobile-upload-doc
    margin-left: 0
    @include media-breakpoint-up(md)
      display: none

.documents-list
  @extend .card-deck
  margin-top: 0
  padding: $spaceMedium
  overflow: auto

.fr-view
  p
    margin-bottom: 0

span.user-mention
  background-color: #caf1f1

.archived-discussion-message
  background: $badge-color
  color: #FFF
  text-align: center
  padding: .75rem
  margin-top: 2rem

@media print
  .discussion-drawer, .drawer-toggle, .discussion-message-selector, .discussion-new-message-block
    display: none !important
  .discussion-container
    height: 100%
    .pseudo-after-print
      height: 30px
  .discussion-page, .discussion-container
    display: block !important
    overflow: visible
  .discussion-page-wrapper
    height: auto
    overflow: visible
  .container
    max-width: 100%
