.user-avatar
  pointer-events: none
  padding:
    top: .5rem
    left: .3rem
  .team-avatar
    font-size: 2.2em
  .team-divider
    margin: 8px 20px
  .modal &, .card.horizontal &
    padding: 0
    margin-right: .7rem
  .flex-member-list &
    &:first-child
      padding-left: 0
    &:last-child
      padding-right: 3px
  &-holder
    &:nth-child(n+9)
      display: none

    .card &
      &:nth-child(n+5)
        display: none
      @include media-breakpoint-down(md)
        &:nth-child(n+4)
          display: none

    @include media-breakpoint-down(lg)
      &:nth-child(n+8)
        display: none

    @include media-breakpoint-down(sm)
      &:nth-child(n+5)
        display: none
