.tribute-container
  z-index: 2147483640

  box-shadow: 0 0 4px rgba(115, 131, 158, .5)
  ul
    background: #FFF
    margin: 0

    li
      border-bottom: 1px solid $separator-color
      padding: 5px 10px

      &.highlight
        background: #3366FF
        color: #FFF

      span
        font-weight: 400

        &.team, &.all
          font-style: italic
          font-weight: 500

        &.partial
          font-size: 80%
          padding-left: 5px
          color: $text-muted-color

      &:has(> span.firstOfType)
        border-top: 2px solid $separator-color
