.header
  $main-menu-width: 440px
  $main-menu-width-md: 340px

  font-family: 'Space Grotesk', sans-serif
  display: flex
  flex-flow: row nowrap
  flex: 0 0 $header-size
  justify-content: space-between
  position: relative
  z-index: 4
  padding: 0
  background-color: white
  border-bottom: 1px solid #dbe1e7
  box-shadow: $header-shadow
  @include media-breakpoint-down(sm)
    flex-basis: auto
    min-height: $header-mobile-size
    padding: 0
  @include media-breakpoint-down(md)
    flex-basis: auto
    height: $header-mobile-size

  body.joint-space &
    @include media-breakpoint-up(md)
      background-color: $shared-space-color
      color: $shared-space-text-color
      z-index: 2

  .logo
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    align-self: stretch
    border-right: 1px solid #d5dde0
    padding: 0 9px

    svg
      align-self: center

    @include media-breakpoint-down(md)
      font-size: 0.9rem
      min-height: $header-mobile-size

      svg
        transform: scale(.75)

    @include media-breakpoint-down(sm)
      svg
        transform: scale(.5)

  &-logo
    display: none
    @include media-breakpoint-down(sm)
      display: flex

    body.authentication &,
    body.embed &
      display: flex
      flex: none
      .logo
        border-right: none
        display: flex
        align-items: center
        img, svg
          flex: none
          height: .6 * $header-size
          @include media-breakpoint-down(sm)
            height: .6382978723 * $header-mobile-size

  &-space-name
    display: flex
    align-items: center
    padding-left: 1rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    @include media-breakpoint-up(md)
      width: calc(50% - #{$main-menu-width-md} / 2)
    @include media-breakpoint-up(lg)
      width: calc(50% - #{$main-menu-width} / 2)
    @include media-breakpoint-down(sm)
      padding-left: 0

    .with-transition &
      transition: padding 0.5s ease, margin 0.5s ease

    // centering .main-menu when sidebar is toggled
    .no-sidebar &
      @media (min-width: 768px) and (max-width: $break-semilarge)
        margin-left: -49px
        padding-left: 59px

    body:not(.no-sidebar) &
      @media (min-width: $break-semilarge + 1)
        margin-left: -49px
        padding-left: 59px

  &-nav
    display: flex
    padding-right: 1rem
    @media (max-width: $break-semilarge)
      padding-right: 1rem
    @include media-breakpoint-down(sm)
      margin-left: auto
    @include media-breakpoint-up(md)
      justify-content: flex-end
      width: calc(50% - #{$main-menu-width-md} / 2)
    @include media-breakpoint-up(lg)
      width: calc(50% - #{$main-menu-width} / 2)

  &-nav-item
    &:not(:first-child)
      margin-left: 1.5rem
    @media (max-width: $break-semilarge)
      &:not(:first-child)
        margin-left: 1rem

    [class*='icon-']
      display: block
      font-size: 1.5rem
      color: $header-icons-color

    .icon-star-filled
      color: $starred-message-star-color

    &.highlighted,
    &.active
      [class*='icon-']
        color: $link-color

    body.joint-space &
      @include media-breakpoint-up(md)
        [class*='icon-']
          color: $shared-space-text-color

        &.highlighted,
        &.active
          [class*='icon-']
            color: white

  &-nav-section
    display: flex
    align-items: center

  &-title
    display: flex
    flex: 1
    min-width: 0
    padding:
      left: 1rem
      right: 1rem
    align-items: center
    justify-content: center
    @media (max-width: $break-semilarge)
      justify-content: flex-start

    .out-of-scheme &
      height: 100%

      @include media-breakpoint-up(md)
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)

      h5
        display: flex

        .meetings &, .mentions &, .starred_items &, .chats &
          @media (max-width: $break-semilarge)
            display: none

    h5
      font-size: 1.3rem
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      @media (max-width: $break-semilarge)
        font-size: 1rem

  &-control
    display: none
    align-items: center
    justify-content: center
    height: 100%
    width: $header-mobile-size
    border-right: 1px solid #cbcdd6
    background-color: white
    transition: color .5s ease
    cursor: pointer
    @include media-breakpoint-down(sm)
      display: flex

    body.discussions &,
    body.groups &,
    body.documents &,
    body.zephyrus_reports &,
    body.iframes &,
    body.users &,
    body.agenda_items &,
    body.apps_instances &,
    body.out-of-scheme &
      position: absolute
      top: 0
      left: 0
      z-index: 1

    &:focus,
    &:hover,
    &:active
      color: rgba($header-icons-color, .6)

    [class^="icon-"], [class*=" icon-"]
      color: currentColor

@media print
  header, .header, .subheader-nav
    display: none
