$message-left-margin: 45px
$message-block-padding: 1.2rem
$message-icon-with: 35px
$shared-message-blockquote-width: 3px
$message-selector-left-margin: 30px

.sl-wrapper
  button
    &:focus
      outline: none

[data-component="MessageSelection"]
  margin-right: $message-selector-left-margin
  cursor: pointer
  position: absolute
  left: -50px
  top: calc(50% - 16px)

.message
  &-selection
    border: none
    background: none

  &-blockquote
    position: absolute
    bottom: 0
    left: 0
    height: calc(100% + 5px)
    border-radius: 1px
    width: $shared-message-blockquote-width
    background-color: $agenda-timeline-color

  &-block
    @extend .card
    position: relative
    text-align: left
    padding: 1.2rem
    border-radius: 0.25rem
    border: none
    flex: 0 0 auto
    margin: 1rem 0
    transition: background-color 0.25s ease-in-out

    &.with-selector
      margin-left: $message-selector-left-margin + 10px

    &.message-share
      margin: 1rem 0 0.25rem
      box-shadow: none
      padding-top: 0
      padding-bottom: 0

    &__highlighted
      background-color: $hightlighted-message-background-color

      .message-share
        background-color: $hightlighted-message-background-color

    &__starred
      background-color: $starred-message-background-color

      .message-share
        background-color: $starred-message-background-color

    &__sending
      &:before
        content: ""
        display: block
        left: 0
        height: 100%
        position: absolute
        top: 0
        width: 100%
        z-index: 2

      opacity: .5

    .from-email
      display: none

    &__from-email
      .from-email
        display: inline-block
      .fr-view
        table
          td, th
            border: none

    &__actionable
      border: 1px solid $message-actionable-border-color

    &__sign
      border: 1px solid $message-actionable-border-color
      > .message-icon
        display: flex
        align-items: center
        justify-content: center
        font-size: 1em

    &__closed
      border-color: $icon-color
      > .message-icon
        border-color: $icon-color
        color: $icon-color

    &__system
      border: 1px solid $message-system-border-color

    .menu-toggle
      @extend .text-muted
      display: block
      color: $icon-color !important
      cursor: pointer
      line-height: 0
      font-size: 32px
      height: 22px

    .edited-label
      @extend .text-muted
      @extend .card-text
      font-size: 0.85rem
  &-meta
    display: flex
    flex-flow: row nowrap
    align-items: flex-start
  &-creds
    @extend .card-text
    font-size: 0.85rem
    margin-top: 0
    width: calc(100% - 160px)

    .from-email
      line-height: 0
      margin-left: .25rem
      position: relative
      top: .15rem

  &-author
    color: $heading-color
    font-weight: bold
    @each $color in $avatar-color-pool
      // colors starting from 0
      $i: index($avatar-color-pool, $color)-1
      &.avatar-modifier-#{$i}
        color: $color
    &__system
      color: $system-avatar-color
      font-weight: bold
  &-actions
    .dropdown-menu
      width: 13.5rem

    a
      display: block
      padding-right: .5rem
      position: relative

      &:focus-visible
        outline: none

      &:after
        border-right: 1px solid $separator-color
        content: ''
        display: block
        position: absolute
        top: 0
        right: 0
        height: 1.4rem

    // for chat page
    &:last-child
      margin-right: 0

      a
        padding-right: 0.25rem

        &:after
          display: none

  &-content
    margin:
      left: $message-left-margin
      top: -0.5rem
    @include media-breakpoint-down(sm)
      margin: 0

  &-body-wrapper
    transition: height 0.5s ease
    overflow: hidden

    .message-block__minimizable.message-block__minimized > .message-content > &
      // initial value is be set in JS to make height transition work
      // we need !important to override this value
      max-height: 6rem !important

    .message-block__minimizable.message-block__minimized.message-block__from-email > .message-content > &
      max-height: 20rem !important

  &-body
    @extend .card-text
    color: $message-text-color
    overflow: auto
    font-size: 1rem
    margin-top: .25rem

    > p
      margin-bottom: 0

    img
      cursor: zoom-in

    a
      color: var(--info)
      transition: opacity .5s ease

      &:hover
        opacity: .5

      &.breadcrumb-link
        color: $link-color
        font-size: .75rem
        font-weight: 500

      &.message-link
        color: $heading-color
        display: block
        margin-top: 1px // to avoid jerking on hover on element's border
        transition: none
        @include media-breakpoint-down(sm)
          margin-bottom: 6px
        &:hover
          opacity: .7

  &-footer
    margin-left: $message-left-margin
    position: relative

    @include media-breakpoint-down(sm)
      margin: 0

    &:before
      bottom: 100%
      height: 0
      content: ''
      display: block
      opacity: 0
      position: absolute
      transition: all .5s ease
      width: 100%

    a.starred-breadcrumbs:not([href])
      cursor: auto
      &:hover
        color: $text-muted-color

    .file-card
      margin: 0.5rem 0 0.25rem
      width: 100%

    .file-link-data
      display: flex
      flex-flow: row nowrap
      align-items: center

      &__file-name
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .attachments-header
      font-size: 90%

    .message-block__starred &
      background: $starred-message-background-color

    .message-block__minimizable.message-block__minimized &:before
      opacity: 1
      height: 1rem

    .message-block__minimizable &:before
      background: linear-gradient(to bottom, rgba($card-background-color,0) 0%, rgba($card-background-color, 1) 100%)

    .message-block__minimizable.message-block__starred &:before
      background: linear-gradient(to bottom, rgba($starred-message-background-color,0) 0%, rgba($starred-message-background-color,1) 100%)

    .message-block__starred .message-share.message-block__minimizable &:before
      background: linear-gradient(to bottom, rgba($starred-message-background-color,0) 0%, rgba($starred-message-background-color,1) 100%)

  &-size_toggle
    display: block
    text-align: center
    padding: .5rem 0 .25rem
    cursor: pointer

    @include media-breakpoint-down(sm)
      padding: 1rem

    .message-block:not(.message-block__minimizable) &
      display: none

    .message-share.message-block__minimizable &
      display: block

    &[data-behavior]:not([href])
      color: $text-muted-color
      font-size: .9rem

      &:hover
        color: $sidebar-active-color

    &:before
      content: attr(data-show-less-text)
      display: block

    .message-block__minimized > .message-footer > &:before
      content: attr(data-show-more-text)

  &-menu
    height: 3rem
    padding-top: .68rem
    position: absolute
    right: 1rem
    top: 0

    a[class^="message-"]
      //padding-top: .9rem
      margin-left: .5rem

    [class^="icon-"]
      font-size: 1.5rem
      color: $icon-color

    .message-action [class^="icon-"]
      font-size: 1.3rem
      line-height: 1.1

    .message-star
      display: none

      .message-block__starred &
        line-height: 0

        [class^="icon-"]
          color: $starred-message-star-color

      .message-block:not(.message-block__starred) &:not(.message-star__starred)
        display: block

      .message-block__starred &__starred
        display: block

    .message-menu-buttons
      display: flex
      padding-top: 5px

  &-avatar
    margin:
      top: 2px
      right: 8px
    .user-avatar
      padding: 0
    @include media-breakpoint-down(sm)
      display: none
  &-icon
    background-color: #FFF
    border: 1px solid $message-actionable-border-color
    border-radius: 50%
    color: $message-actionable-icon-color
    font-size: 1.25rem
    height: $message-icon-with
    left: calc(-1 * $message-icon-with/2)
    top: calc(-1 * $message-icon-with/3)
    padding: .25rem .3rem
    position: absolute
    width: $message-icon-with

    .icon-vote
      font-size: 1.5rem

    .icon-user
      font-size: 1.2em

    .icon-share
      position: relative
      font-size: 1.2em
      right: 0.1em

    .icon-archive-unpack, .icon-archive
      bottom: .15em
      left: .25em
      font-size: .8em
      position: relative

    .message-block__system &
      color: $message-system-border-color
      border: 1px solid $message-system-border-color

  &-actionable-panel
    border-top: 1px solid $message-actionable-border-color
    margin-left: -$message-block-padding
    margin-top: $message-block-padding
    margin-bottom: -$message-block-padding
    padding: 0.5rem $message-block-padding
    width: calc(100% + #{$message-block-padding} + #{$message-block-padding})

    .message-actionable-panel-summary
      width: 100%

      @include media-breakpoint-down(sm)
        text-align: center

    .message-actionable-panel-main
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center

      @include media-breakpoint-down(sm)
        flex-direction: column
        height: auto
        padding: 0 1rem 0.5rem

        @media print
          flex-direction: row

        .message-action
          margin-top: .5rem

    &__closed
      border-color: $icon-color

    button.button
      border-color: lighten($link-color, 25)

      &:disabled:hover
        border-color: lighten($link-color, 25)

      &.is-selected
        border-color: $link-color
        background-color: lighten($link-color, 50)

        &:disabled:hover
          border-color: $link-color

    button.button + .button
      margin-left: .5rem

    & > *
      display: inline-block

.message-header
  display: none

.print-info-block
  display: none

@media print
  .pvm, .date-separator-wrapper, [data-component="MessageSelection"], .message-menu
    display: none
  .no-print
    display: none !important
  .message-share
    .message-size_toggle
      display: none !important
  .message-block
    display: none
    &.with-selector
      margin-left: 0
    .message-header
      position: absolute
      display: block
    &.printable
      display: block
    .message-block
        display: block
  .print-info-block
    display: block
    padding-top: 15px
    .result
      color: $text-muted-color
      font-size: 0.85rem
  hr
    page-break-after: avoid !important
  .row-cols-md-2 > *
    flex: 0 0 50%
    max-width: 50%

