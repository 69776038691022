// Base class
.tooltip.fade
  background-color: transparent

  .arrow
    display: none

// Wrapper for the tooltip content
.tooltip-inner
  background-color: #20142E
