.user-menu
  margin-left: auto

  .user-avatar
    padding-top: 0
    padding-bottom: 0
    margin-right: .5rem

  .user-card
    flex: 1 1 auto
    display: flex
    align-items: center
    min-width: 0

  li
    position: relative

  a
    color: inherit

  .user-actions
    z-index: 3
    height: 0
    overflow: hidden

    a
      display: block
      padding: 0.5rem 1rem
      margin-left: 2rem
      color: #9e9eb0

      &:hover
        color: white

  .nav-link
    cursor: pointer
    &:focus
      outline: none
    &.expanded + .user-actions
      height: auto

  .dropdown-toggle
    padding-top: 0
    padding-bottom: 0

    &::after
      color: white
      transition: transform .25s ease
      transform: rotate(-180deg)

    &.expanded
      &::after
        transform: rotate(0deg)
