$avatar-width: 18px
$badge-height: 26px
$badge-font-size: 13px

$message-actors-padding: 0.65rem

.message-actors
  background-color: $card-background-color
  border-radius: $card-border-radius
  margin: 0.5rem 0
  padding: $message-actors-padding $message-actors-padding 0

  .dropdown
    height: $badge-height
    margin:
      right: $message-actors-padding
      bottom: $message-actors-padding
    vertical-align: middle

    a
      font-size: $badge-font-size

    .dropdown-toggle
      background-color: $card-background-color
      color: $link-color
      height: $badge-height
      line-height: 1.2
      padding: 0 0.2rem 0 0.5rem

      .limited
        line-height: 0

    .dropdown-menu
      min-width: 6rem

      .dropdown-item
        padding: 0.25rem .75rem

  .message-actor
    align-items: center
    background-color: $separator-color
    border-radius: .25rem
    display: inline-flex
    height: $badge-height
    margin:
      right: $message-actors-padding - .15rem
      bottom: $message-actors-padding
    padding: .4rem
    vertical-align: middle

    .user-avatar
      margin-right: 5px
      padding: 0

      .avatar-image
        align-items: flex-end
        font-size: .5rem
        height: $avatar-width
        min-width: $avatar-width
        width: $avatar-width

      .initials
        align-items: center

    .full-name
      font-size: $badge-font-size

    .icon-cancel-circled
      font-size: 1rem
      margin-left: .5rem
