.container
  @include make-container()
  @include media-breakpoint-down(sm)

  max-width: map-get($container-max-widths, "g")
  &.no-subheader
    @include media-breakpoint-down(sm)

.flex-row
  @include make-row()

%grid-column
  position: relative
  width: 100%
  min-height: 1px
  // Prevent columns from collapsing when empty
  padding-right: calc($grid-gutter-width / 2)
  padding-left: calc($grid-gutter-width / 2)

.flex-col
  @extend %grid-column
  flex-basis: 0
  flex-grow: 1
  max-width: 100%

.nonflex-col
  @extend %grid-column
  flex: 0 0 auto
  width: auto

.col1of2,
.col1of3, .col2of3,
.col1of4, .col2of4, .col3of4,
.col1of5, .col2of5, .col3of5, .col4of5,
.col1of6, .col2of6, .col3of6, .col4of6, .col5of6
  @extend %grid-column

.col1of2, .col2of4, .col3of6
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(1/2))
    max-width: percentage(calc(1/2))

.col1of3, .col2of6
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(1/3))
    max-width: percentage(calc(1/3))

.col2of3, .col4of6
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(2/3))
    max-width: percentage(calc(2/3))

.col1of4
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(1/4))
    max-width: percentage(calc(1/4))

.col3of4
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(3/4))
    max-width: percentage(calc(3/4))

.col1of5
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(1/5))
    max-width: percentage(calc(1/5))

.col2of5
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(2/5))
    max-width: percentage(calc(2/5))

.col3of5
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(3/5))
    max-width: percentage(calc(3/5))

.col4of5
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(4/5))
    max-width: percentage(calc(4/5))

.col1of6
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(1/6))
    max-width: percentage(calc(1/6))

.col5of6
  +media-breakpoint-up(lg)
    flex: 0 0 percentage(calc(5/6))
    max-width: percentage(calc(5/6))
