.starred-items
  display: block
  margin-top: .5rem
  font-size: .75rem

  .icon-mention
    padding-left: .15rem
    font-size: 1.25rem

  .breadcrumb-link
    font-weight: 500

  .starred-breadcrumbs
    color: $text-muted-color

    &:hover
      color: $sidebar-active-color

    .breadcrumbs-separator
      color: #bac1d3
      padding: 0 .25rem
      word-break: break-all
