// conflict with ellipses menu
.be-modal-dialog-overlay, .be-modal-wrapper-overlay
  z-index: 101

// no need to show logo
.be-header .be-logo
  display: none

.be
  a.be-copy-link-btn
    color: #6c757d

    &:hover
      color: #2f3063

.bp-default-logo
  visibility: hidden

.bp-media-play-button
  padding-top: 0

.bp-controls
  @include media-breakpoint-down(sm)
    transform: scale(.8) translateX(-50%)
    transform-origin: left

  .bp-controls-btn
    border: none

.be-item-details
  .bdl-ItemSubDetails-modifiedBy
    margin-right: 5px

button.be-item-label
  color: black

  &:focus
    color: black

button[class*='btn']
  font-size: 1em
  border-color: #bdc3c7

.file-preview
  display: flex
  flex-direction: row
  flex: 1 1 100%

.file-preview-container
  display: flex
  flex: 1 1 100%

.dropdown-menu-element
  z-index: 1100

.be-modal-dialog-content
  label
    display: block

.bp-find-bar .bp-doc-find-close,
.bp-find-bar .bp-doc-find-next,
.bp-find-bar .bp-doc-find-prev,
.bp-find-bar .bp-doc-find-search
  margin-left: 0

.modal
  padding: 0

  & &-content
    margin-top: 0
    &.small
      min-height: 10%

  & &-dialog
    background-color: transparent
    animation: none
    padding: 0

  & &-header
    margin-right: 0
    &.with-actions
      align-items: center
      padding: 0.5rem 1rem
      flex: 0 1 10%

  & &-title
    flex: initial
    &.with-actions
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      max-height: 60px
      overflow: visible

.bcp
  .bcp-footer
    .bcp-footer-left
      .btn-content
        display: inline

    .bcp-footer-right
      .bcp-footer-actions
        .btn
          width: 100px

          .btn-content
            display: none

          &:after
            content: 'Cancel'

          &.btn-primary
            &:after
              content: 'Next'
            &[data-content]:after
              content: attr(data-content)

.bcs
  .bcs-VersionsItemButton
    &.bcs-is-selected
      background-color: #ecf4fc
      font-weight: 700
    &:hover
      padding: 15px 10px
    &:focus
      border: 1px solid rgb(0, 97, 213)
      color: #222

.bcs-VersionsItemActions
  .bcs-VersionsItemAction
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(4)
      display: none

.download-box-file .icon-download
  color: #6f6f6f

#left-custom-toolbar-buttons-container
  display: flex
  gap: 20px

  button.box-ai-button
    font-family: var(--font-family-sans-serif)

    &:hover
      svg
        path
          fill: #494949

.bp
  .bp-content
    flex-direction: column

  .bp-image
    position: relative
    margin-bottom: 20px

    &:before
      height: 0

    img
      position: static
      left: 0
      top: 0
      margin-top: 20px

.bp-header
  padding-left: 16px

  .bp-header-btns
    display: flex