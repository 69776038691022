.app-instance-iframe-container
  position: relative

  .iframe-footer
    position: absolute
    bottom: 0
    left: 0
    height: 25px
    width: 100%
    background-color: white
