.select2-container--knowa
  border: 1px solid $border-control-color
  border-radius: 3px
  display: block

  &.select2-container--disabled
    opacity: 0.5
    pointer-events: none

  @include media-breakpoint-down(sm)
    margin-bottom: 1rem

  .select2-selection
    background: white image-url('~images/select-arrow.svg') no-repeat right 10px center
    height: auto
    padding: 0.375rem 0.75rem

    &--single
      .select2-selection__rendered
        padding-left: 0
        padding-right: 0

    &:focus
      outline: none
      border-radius: 1px
      border-color: #80bdff
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)

  .select2-results__options
    max-height: 300px
    overflow: auto

  .select2-results__option
    .account-option
      font-weight: bolder
    .scheme-option
      padding-left: 1rem

    &--highlighted[aria-selected]
      background-color: #f8f9fa

  .select2-dropdown
    border: 1px solid $border-control-color
    top: -1px

  .select2-search__field
    border: 1px solid $border-control-color
    border-radius: 3px

    &:focus
      outline: none
      border-color: #80bdff
      box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25)

  .select2-results__option
    padding: 0.25rem 0.5rem

