// TODO: check usage and remove
.group-chats-list
  .card-title
    display: flex
    align-content: center
    max-width: calc(100% - 120px) //subtracting width of avatar and status label

  .card-title-primary-wrapper
    display: flex
    align-items: center

  .card-title-primary
    margin-right: 10px

  .card-title-secondary
    margin-top: 3px

  .card-status-label
    align-self: flex-start
