body
  font-family: $font-primary-regular
  color: $heading-color

h1
  @extend .h1

h2
  @extend .h2

h3
  @extend .h3

h1, h2, h3, h4, h5, h6
  margin: 0
  color: $heading-color

a, .text-link
  color: $link-color
  cursor: pointer
  &:hover, &:active, &:focus
    color: $link-hover-color
    text-decoration: none
  &.disabled, &[disabled]
    color: lighten($link-color, 20%)
    cursor: default
  .required
    color: $text-red-color

.plain-text-link,
.plain-text-link:not([href]):not([tabindex])
  color: inherit
  cursor: pointer
  &:hover, &:active, &:focus
    color: $link-hover-color
    text-decoration: none

.text-muted
  color: $text-muted-color

.text-small
  font-size: 15px

[class^="icon-"], [class*=" icon-"]
  font-size: 1.3em

.icon-small
  font-size: 1.1em
