.react-dropdown-tree-select
  .dropdown.radio-select
    width: 100%

    .dropdown-trigger
      width: 100%
      border: solid 1px lightgray
      border-radius: 4px
      padding: 6px 12px

      &:active,
      &:focus
        color: inherit
        border-color: #80bdff
        outline: 0
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)

      .tag-item
        margin: 0

        &:last-child
          max-width: 150px

        .search
          padding: 0
          margin: 0 2px
          border: none
          max-width: 100%
          height: 36px

          &::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: hsl(0,0%,50%)

        .placeholder
          padding: 3px
          color: $text-muted

        .tag
          padding: 4px
          margin-right: 4px
          border-radius: 4px

          .tag-remove
            outline: none
            vertical-align: top
            padding: 4px
      &.arrow
        &.top:after,
        &.bottom:after
          float: right
          display: inline-block
          content: ""
          margin: 12px 14px
          padding: 3px
          border: solid hsl(0,0%,80%)
          border-width: 0 2px 2px 0
          transform: rotate(45deg)
          -webkit-transform: rotate(45deg)

        &:hover
          &.top:after,
          &.bottom:after
            border-color: hsl(0,0%,60%)

        &:focus
          &.top:after,
          &.bottom:after
            border-color: hsl(0,0%,20%)

    .dropdown-content
      width: 100%
      padding: 4px 0
      border: none
      border-radius: 4px
      box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)

      .no-matches
        padding: 0
        margin-left: 12px
        color: $text-muted

      .root
        padding: 0

        .infinite-scroll-component:empty
          text-align: center
          color: $text-muted

          &:after
            content: 'No folders'

        .toggle
          margin-left: 12px
          display: inline
          vertical-align: middle

          &.collapsed
            margin-right: 1px

        .radio-item
          margin-top: 1px

        .node
          &:hover
            background-color: rgba(218,218,218,0.19)

          label
            margin-bottom: 2px

            .node-label
              vertical-align: middle
