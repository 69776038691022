.agenda-items
  width: 100%
  max-width: 1575px
  padding: 14px 15px 50px
  margin: 0 auto

  @include media-breakpoint-up(md)
    padding: 14px 120px 50px

.agenda-items-list-container
  position: relative

.agenda-timeline
  position: absolute
  top: 0
  left: -51px
  height: 100%
  width: 1px
  background-color: $agenda-timeline-color

.agenda-items-list > *:first-child:not(.ui-sortable-helper) > .agenda-item-row .time-section::before
  content: ''
  position: absolute
  top: 0
  left: 0
  height: 50%
  width: 100%
  background-color: $app-background

.agenda-items-list > *:last-child:not(.ui-sortable-helper) > .agenda-item-row:only-child .time-section::after
  content: ''
  position: absolute
  top: 50%
  left: 0
  height: 50%
  width: 100%
  background-color: $app-background

.agenda-items-list > *:last-child:not(.ui-sortable-helper) > .agenda-sub-items-list > *:last-child:not(.ui-sortable-helper) .time-section::after
  content: ''
  position: absolute
  top: 50%
  left: 0
  height: 50%
  width: 100%
  background-color: $app-background

.card-section-right
  margin-left: 5px

.agenda-items-list .agenda-sub-items-list .agenda-item-row
  padding-left: 9px

.agenda-subitems-wrapper
  .card-documents
    display: none

.agenda-items-list
  position: relative

  .card-auxiliary
    display: flex
    align-items: center

    .account-role-badge
      margin-right: 1rem

.agenda-sub-items-list
  margin-top: 1rem

.agenda-items-list,
.agenda-sub-items-list
  transition: all 0.2s

  .agenda-item-container
    position: relative
    transition: all 0.2s

  .agenda-item-container + .agenda-item-container
    margin-top: 1rem

  .ui-sortable-helper
    transition: none !important
    animation: pulsar 0.5s alternate infinite

    @keyframes pulsar
      0%
        transform: rotate(0.2deg)
        animation-timing-function: ease-in
      50%
        transform: rotate(-0.3deg)
        animation-timing-function: ease-out

  .agenda-item-row
    position: relative
    display: flex
    align-items: center

    .contact-details
      border: 0
      background: none
      padding: 0

    .time-section
      position: absolute
      top: 0
      right: calc(100% + 14px)
      display: flex
      justify-content: center
      align-items: center
      height: 100%
      width: 75px

      .start-time
        position: relative
        line-height: 2.3
        color: $header-icons-color
        white-space: nowrap
        background-color: $app-background

        &.text-highlighted
          color: $success-color
          font-weight: bold

      @media (max-width: $break-medium - 1px)
        right: 0
        left: 15px
        align-items: flex-start

        .start-time
          background-color: transparent

        &.time-section-break
          left: 0
          align-items: center

          .start-time
            padding-right: 1.25rem
            padding-left: calc(1.25rem + 25px)
            background-color: $app-background

    .card
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: flex-start
      padding-top: 1.25rem

      @media (max-width: $break-medium - 1px)
        padding-top: 35px

      .card-title
        display: flex
        font-weight: 500
        color: $heading-color

      .card-title, .card-text
        overflow-wrap: break-word
        word-wrap: break-word
        word-break: break-word

      .card-item-index
        white-space: nowrap
        padding-right: 10px

      .card-documents
        display: none

        @include media-breakpoint-up(md)
          display: flex
          font-size: 0.9rem

      .present
        color: #2f3063

      .card-duration
        display: flex
        [class^="icon-"]
          font-size: 0.9rem
        .icon-clock
          padding: 2px

        @media (max-width: $break-medium - 1px)
          position: absolute
          top: 6px
          right: 20px

      .card-duration,
      .card-documents
        align-items: center
        justify-content: flex-end
        white-space: nowrap
        color: $meeting-pack-muted-text-color

      .card-attendees,
      .card-duration
        padding-bottom: 10px

      .card-attendees
        display: none

        @include media-breakpoint-up(md)
          display: flex
          flex-wrap: nowrap
          margin: 0
          justify-content: flex-end

          .flex-member-list
            flex-wrap: nowrap

          .user-avatar
            padding-top: 0

          .manage-users-small
            margin: 0 0 0 0.3rem

      .card-section-left
        display: flex
        flex-direction: column
        align-self: stretch
        width: 100% !important

        .agenda-item-link
          align-self: stretch
          width: 100%
          height: 100%

    .break
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center

      .break-item-index
        display: none

      .break-line
        border-bottom: 1px solid $meeting-pack-break-line-color
        flex: 1 1 auto

      .break-title
        color: $heading-color
        margin: 0 1.25rem
        max-width: 50%
        text-align: center
        text-transform: uppercase

      .break-duration
        position: absolute
        top: 0
        right: 0
        background-color: #f3f6f9
        color: $meeting-pack-muted-text-color
        padding: 0 1.25rem
        white-space: nowrap

        &:empty
          margin: 0

    &:hover .agenda-action-edit.agenda-action
      visibility: visible

    .agenda-actions-container
      display: flex
      align-items: center
      height: 100%
      padding: 0 14px

      @media (max-width: 1024px)
        .ui-sortable-handle
          display: none

    .actions-section
      display: none

      @include media-breakpoint-up(md)
        position: absolute
        top: 0
        left: 100%
        height: 100%
        display: block

        .agenda-action
          padding: 7px
          color: rgba(0, 0, 0, 0.54)
          font-size: .7rem

          [class^="icon-"]
            transition: opacity .5s ease

          &:hover
            cursor: pointer

            [class^="icon-"]
              opacity: .5

        .agenda-action-edit
          font-size: .8rem
          visibility: hidden

        .agenda-item-status
          font-size: 1.7em
          margin-right: 9px

        .dropdown-toggle::after
          display: none

        .dropdown-item
          color: #2F3063
          cursor: pointer

          &:active
            background-color: #f4f4f4

        .dropdown-item.active
          background-color: #f4f4f4


.agenda-item-container:not(:first-child)
  .start-time
    &[data-duration="0"]
      display: none

.agenda-sub-items-list
  .start-time
    &[data-duration="0"]
      display: none

