$container-max-widths: (sm: 520px, md: 720px, lg: 860px)
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1420px)

// Media
$break-small: 576px
$break-medium: 768px
$break-large: 992px
$break-semilarge: 1100px
$break-x-large: 1200px
$break-xx-large: 1420px

// Spaces
$spaceXSmall  : .5rem
$spaceSmall   : 1rem
$spaceMedium  : 1.5rem
$spaceLarge   : 3rem
$spaceXLarge  : 5rem

// Colors and Backgrounds
$text-muted-color: #7d8498
$text-red-color: #e84c51
$light-red: #E97B7B
$separator-color: #e3e7ed
$app-background: #f3f6f9
$sidebar-active-color: #483959
$heading-color: #2f3063
$card-background-color: white
$card-text-color: #323232
$link-color: #0095ed
$link-hover-color: #53a9f5
$link-active-color: #2981ce
$icon-color: #b4b9c7
$input-text-color: #302e67
$input-placeholder-color: rgba(125, 132, 152, 0.4)
$success-color: #82ca4c
$notification-badge-color: #ededed
$notification-badge-warning-color: $text-red-color
$notification-badge-pending-color: #E0A650
$notification-badge-mention-color: $notification-badge-pending-color
$muted-badge-color: #46485A
$notification-icon-color: #a8abbb
$card-meta-color: $notification-icon-color
$meeting-pack-break-line-color: #9e9eb0
$meeting-pack-muted-text-color: $notification-icon-color
$badge-color: #c4c4c4
$avatar-color: #cbcdd6
$avatar-text: $heading-color
$subheader-link-color: $text-muted-color
$shared-space-color: #79c26c
$shared-space-text-color: #defde5
$shared-space-border: 3px
$message-text-color: $input-text-color // was #71738a
$standard-block-shadow: 2px 2px 5px rgba(115, 131, 158, 0.15)
$input-shadow: inset 1px 1px 3px rgba(115, 131, 158, 0.22)
$header-shadow: 0px 19px 19px rgba(133, 167, 184, 0.06)
$header-icons-color: #a8abbb
$agenda-timeline-color: #a8abbb
$border-control-color: #ced4da
$starred-message-background-color: #fffcd7
$hightlighted-message-background-color: #d7fffc
$starred-message-star-color: #F9CC46
$message-actionable-border-color: #F6D39E
$message-actionable-icon-color: darken($message-actionable-border-color, 25)
$message-system-border-color: #46abed
$search-result-author-color: #4C8D8A
$system-avatar-color: #9647D7
$unpublished-color: $light-red
$published-color: #AECC45
$updated-color: #F5D21B
$account-admin-color: #E2F2C5
$account-guest-color: #FDE4E5
$publish-preview-color: #F8CB53
$dropdown-red-bg-color: #fdebee

// Other Variables
$card-border-radius: 5px
$chat-box-height: 96px
$avatar-height: 2.2rem
$avatars-spacing: 0.3rem
$header-size: 49px
$header-mobile-size: 49px
$sidebar-width: 310px
$input-padding: 0.82rem

// $font-primary-regular: 'GT Eesti Pro Display Regular'
// $font-primary-medium: 'GT Eesti Pro Display Medium'

$font-primary-regular: var(--font-family-sans-serif)

$navbar-dark-toggler-icon-bg: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzIgMzInIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPScjMDA5NWVkJyBzdHJva2Utd2lkdGg9JzInIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBkPSdNNCA4aDI0TTQgMTZoMjRNNCAyNGgyNCcvPjwvc3ZnPg==")
$toggle-transition: 0.5s cubic-bezier(0.77,0.2,0.05,1.0)
