.flash-container
  position: relative

.alert
  position: absolute
  width: 100%
  z-index: 4

  padding: $alert-padding-y $alert-padding-x
  border: $alert-border-width solid transparent
  opacity: 0.95
  @include border-radius($alert-border-radius)

  &.is-success
    @include alert-variant(theme-color-level(success, -10), theme-color-level(success, -9), theme-color-level(success, 6))

  &.is-warning
    @include alert-variant(theme-color-level(warning, -10), theme-color-level(warning, -9), theme-color-level(warning, 6))

  &.is-danger
    @include alert-variant(theme-color-level(danger, -10), theme-color-level(danger, -9), theme-color-level(danger, 6))

  &.is-info
    @include alert-variant(theme-color-level(info, -10), theme-color-level(info, -9), theme-color-level(info, 6))

  &.is-secondary
    @include alert-variant(theme-color-level(secondary, -10), theme-color-level(secondary, -9), theme-color-level(secondary, 6))

  .close
    margin-top: -2px
