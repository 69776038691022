.flex-nowrap
  @extend .flex-lg-nowrap

.align-horizontally-center
  @extend .justify-content-center

.align-horizontally-between
  @extend .justify-content-between

.align-horizontally-around
  @extend .justify-content-around

.align-vertically-center
  @extend .align-items-center

.align-vertically-end
  @extend .align-items-end

.align-self-right
  margin-left: auto !important

.flex-sm-wrap
  @include media-breakpoint-down(sm)
    flex-wrap: wrap !important
