.dropdown-menu.datepicker-dropdown.datepicker
  font-size: 0.8rem
  color: $heading-color

  th
    font-weight: normal

  td.active,
  span.month.focused,
  span.year.focused,
  span.decade.focused,
  span.century.focused
    color: white
    background: $link-color

    &:hover
      @extend .button, :hover

  td.today, td.today:hover,
  span.month.active, span.month.active:hover,
  span.year.active, span.year.active:hover,
  span.decade.active, span.decade.active:hover,
  span.century.active, span.century.active:hover
    background-image: none
    background-color: #fef0b5
    color: $heading-color
    text-shadow: none

.dropdown
  &:not(.btn-group)
    display: inline-block

  // your styles should be added here after bootstrap is cut out
  &-toggle
    white-space: nowrap
    display: flex
    align-items: center
    &::after
      width: auto
      height: auto
      border: none
      content: $icon-dropdown-arrow
      font:
        family: 'knowa-icons'
        size: 1.3rem
    [class^="icon-"], [class*=" icon-"]
      + span
        margin-left: .4rem
        @include media-breakpoint-down(sm)
          margin-left: 0

  &-item
    white-space: initial

    &.delete
      color: red

.dropdown-menu
  &.wide
    @include media-breakpoint-up(md)
      min-width: 15rem
  @include media-breakpoint-down(sm)
    font-size: .8rem

[data-toggle="collapse"]
  &.collapsed .if-not-collapsed
    display: none
  &:not(.collapsed) .if-collapsed
    display: none
