$message-closing-options-padding: $message-actors-padding

.message-closing-options
  background-color: $card-background-color
  border-radius: $card-border-radius
  margin-bottom: 0.5rem
  padding: $message-closing-options-padding
  font-size: 0.8rem

  &.message-closing-options-error
    border: solid 1px $text-red-color
