.list-heading
  display: flex
  flex-flow: row wrap
  align-items: center
  justify-content: space-between
  margin-bottom: $spaceSmall

  &#{&}__with-sorting
    position: relative

    @include media-breakpoint-down(sm)
      justify-content: space-between

    @media only screen and (max-width: 350px)
      flex-wrap: wrap

    & h4
      margin-right: 0

    .list-heading-dropdown
      display: flex
      align-items: center
      left: 50%
      top: 8% // fix for IE
      margin-right: 1rem
      position: absolute
      transform: translateX(-50%)
      z-index: 1

      @include media-breakpoint-down(sm)
        position: static
        margin-right: 0
        transform: initial

      @media only screen and (max-width: 350px)
        order: 3
        margin-top: .5rem
        width: 100%

      span
        color: $text-muted-color
        font-size: .875rem
        margin-right: .5rem
        white-space: nowrap

      .dropdown-toggle
        border: 1px solid #9e9eb0
        border-radius: 3px
        background: #FFF
        color: $heading-color
        justify-content: space-between
        line-height: 1
        min-width: 7rem
        padding: .35rem .35rem .45rem .75rem
        text-transform: capitalize

        &::after
          margin-bottom: -3px

      .dropdown-menu
        min-width: 7rem
        padding: .25rem 0

      .dropdown-item
        padding: .25rem .6rem .25rem .75rem
        text-transform: capitalize

    .list-heading-actions
      @include media-breakpoint-down(sm)
        flex: initial
      @media only screen and (max-width: 350px)
        flex: 1
  h4
    margin-right: $spaceSmall
    color: $subheader-link-color
    font-weight: 400
    font-size: 1.2rem

    &.elevated-on-mobile
      @include media-breakpoint-down(sm)
        margin-bottom: $spaceXSmall

  &-actions
    display: flex
    flex: 1

  .btn-groups-view
    background: transparent
    border: none
    border-radius: 3px
    color: #2E2F65
    margin-left: 5px
    padding: 3px 5px


    @media (max-width: $break-small)
      display: none

    &--active
      background: #2E2F65
      color: #FFF

