.navigation
  padding-left: 0
  @include media-breakpoint-down(md)
    padding-left: .5rem
  &-menu
    align-items: center
    @include media-breakpoint-down(md)
      align-items: stretch
    &-item
      display: flex
      flex-flow: row nowrap
      @include media-breakpoint-down(md)
        flex-flow: column nowrap
    &-link
      display: flex
      align-items: center
      &.limited, > .limited
        max-width: 10.5em
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        @include media-breakpoint-down(md)
          max-width: initial
          overflow: initial
          text-overflow: initial
          white-space: initial
      > .limited
        line-height: 1.2
        display: inline-block
        vertical-align: middle
  .dropdown-menu
    // fix for strange BS dropdown behavior on mobile
    @include media-breakpoint-down(sm)
      position: initial !important
      transform: none !important
    @include media-breakpoint-down(md)
      position: initial !important
      transform: none !important
  .user-avatar
    padding-top: 0
    padding-left: 0
    @include media-breakpoint-down(md)
      padding-bottom: 0.5rem
