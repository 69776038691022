.sidebar
  font-family: 'Space Grotesk', sans-serif
  background-color: #181023
  color: white
  display: flex
  flex-flow: column nowrap
  position: relative
  overflow: visible
  max-height: 100%

  min-width: 0
  z-index: 3

  @include media-breakpoint-down(sm)
    z-index: 12

  ul, li
    list-style-type: none
    padding-left: 0

  .sidebar-section
    flex: 0 0 auto
    @include media-breakpoint-down(sm)
      order: 2

    &.sidebar-user-menu
      overflow: visible
      padding: .625rem 1rem
      @include media-breakpoint-down(sm)
        order: 1
        border-bottom: 1px solid #23232D

      &.highlighted
        background-color: $sidebar-active-color


  .sidebar-sections-wrapper
    position: relative
    flex: 1 1 100%
    @include media-breakpoint-down(sm)
      order: 2

    &.ps
      overflow-x: visible !important

@media print
  .sidebar
    display: none
