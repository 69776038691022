@import '../base/avatar-color-pool'

%round-toggle
  display: inline-block
  color: $avatar-color
  border-radius: 50%
  min-width: 2.2rem
  line-height: 1
  text-align: center
  margin-right: 0.1rem
  outline: none

%round-toggle-action
  background-color: white
  border: solid 1px $avatar-color
  margin-left: .25rem
  top: 0
  min-width: 0
  display: inline-block
  width: $avatar-height
  height: $avatar-height
  line-height: .9545454545 * $avatar-height
  justify-content: center
  align-items: center
  color: $heading-color
  font-size: .9rem
  .flex-action-list &
    margin-left: 0

.round-toggle
  @extend %round-toggle
  &.action
    @extend %round-toggle-action
    margin-left: 0

  &.initials
    background-color: $avatar-color
    border: solid 1px $avatar-color
    color: white
    width: $avatar-height
    height: $avatar-height
    padding-bottom: 1px
    font-size: 0.9rem
    display: flex
    align-items: center
    justify-content: center

    &.avatar-image
      background-size: cover
      background-position: center

    &:hover
      transition: all .2s linear
      background-color: lighten($avatar-color, 5%)
      border-color: lighten($avatar-color, 5%)
    .card-mascot.large &,
    .user-avatar.large &
      font-size: 37px
      width: $avatar-height * 3
      height: $avatar-height * 3
      overflow: hidden
    .card-mascot.large &
      margin:
        left: auto
        right: auto
        bottom: 1rem
    img
      width: 100%
      height: auto
    &.system-avatar
      background-color: $system-avatar-color
      border-color: $system-avatar-color

    @each $color in $avatar-color-pool
      // colors starting from 0
      $i: index($avatar-color-pool, $color)-1
      &.avatar-stub.avatar-modifier-#{$i}
        background-color: $color
        border-color: $color
