#cover-spin
  position: fixed
  width: 100%
  left: 0
  right: 0
  top: 0
  bottom: 0
  background-color: rgba(255,255,255,0.7)
  z-index: 9999
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  & > span
    display: block
    text-align: center
    position: relative
    padding-left: 10px
    padding-right: 10px

.ldBar
  position: relative
  margin-bottom: 40px

.ldBar > .ldBar-label
  text-align: center

  &:after
    content: "%"
    display: inline

.ldBar.no-percent .ldBar-label:after
  content: ""
