@function translate-media-condition($breakpoint)

  $breakpoints:                         ("small-only":   "(max-width: #{$break-small})")
  $breakpoints: map-merge($breakpoints, ("small-up":     "(min-width: #{$break-small + 1})"))
  $breakpoints: map-merge($breakpoints, ("medium-up":    "(min-width: #{$break-medium + 1})"))
  $breakpoints: map-merge($breakpoints, ("medium-only":  "(min-width: #{$break-medium + 1}) and (max-width: #{$break-large})"))
  $breakpoints: map-merge($breakpoints, ("medium-down":  "(max-width: #{$break-medium})"))
  $breakpoints: map-merge($breakpoints, ("large-up":     "(min-width: #{$break-large + 1})"))
  $breakpoints: map-merge($breakpoints, ("semilarge-only":   "(min-width: #{$break-semilarge + 1}) and (max-width: #{$break-x-large})"))
  $breakpoints: map-merge($breakpoints, ("large-only":   "(min-width: #{$break-large + 1}) and (max-width: #{$break-x-large})"))
  $breakpoints: map-merge($breakpoints, ("large-down":   "(max-width: #{$break-large})"))
  $breakpoints: map-merge($breakpoints, ("x-large-up":   "(min-width: #{$break-x-large + 1})"))
  $breakpoints: map-merge($breakpoints, ("x-large-down": "(max-width: #{$break-x-large})"))
  // had to use this strange thing in order to rewrite it to SASS syntax
  // unfortunately, SASS is not that friendly to multiline syntax so
  // its rather map-merge OR unreadable line kilometer long

  @return map-get($breakpoints, $breakpoint)

// The media mixin
=screen($args...)
  $query: ""

  @each $arg in $args
    $op: ""

    @if $query != ""
      $op: " and "

    $query: $query + $op + translate-media-condition($arg)

  @media only screen and #{$query}
    @content
