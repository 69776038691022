.form-block
  @extend .form-group

.form-row
  // your styles should be added here after bootstrap is cut out

.form-field
  font-size: 1rem

  &[type="text"]:disabled
    cursor: text

form .actions
  display: flex
