// general helpers
.text-center
  text-align: center

.text-right
  text-align: right

.text-left
  text-align: left

.float-left
  float: left

.float-right
  float: right

.no-float
  float: none

.flex-right
  margin-left: auto !important

.flex-left
  margin-right: auto

.overflow-scroll
  overflow-y: scroll

.centered
  margin:
    right: auto
    left: auto

.clearfix
  +clearfix

.hidden
  display: none

.is-on-right
  right: 0px
  @include media-breakpoint-down(sm)
    right: .7rem

.scroll-fix
  -webkit-overflow-scrolling: touch

.allow-scroll
  overflow: auto

.empty
  text-shadow: 0 2px white
  font-size: 1rem
  font-weight: bold
  color: rgba($subheader-link-color, .75)
  text-align: center

@mixin text-ellipsis($element-max-width)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: #{$element-max-width}rem

// responsive helpers
.small-screen-only-off
  +screen(small-only)
    display: none

.small-screen-only-on
  display: none
  +screen(small-only)
    display: block

.medium-screen-only-off
  +screen(medium-only)
    display: none

.medium-screen-only-on
  display: none
  +screen(medium-only)
    display: block

.no-wrap
  white-space: nowrap

.font-weight-500
  font-weight: 500

.flex-grow-0
  flex-grow: 0

.d-contents
  display: contents
