.message-separator
  position: relative
  margin: 0 1rem 1rem
  display: flex
  align-items: center
  justify-content: center
  &::before
    content: ''
    border-top: 2px solid $separator-color
    width: 100%
    position: absolute
    top: 50%
    left: 0
    transform: translateY(-50%)
  &::after
    content: 'New messages'
    text-transform: uppercase
    border: 1px solid $separator-color
    display: block
    border-radius: 1rem
    padding: 3px 7px
    color: #a7a7a7
    text-shadow: 1px 1px 0px white
    background-color: $app-background
    font-size: 9px
    position: relative
