.attached-document-list
  &.column-view
    display: flex
    flex-wrap: wrap
    margin:
      left: -5px
      right: -5px

    > .document-card,
    .attached-document-card
      flex: 0 0 auto
      justify-content: center
      width: calc(50% - 10px)
      margin: 5px

    .document-card
      padding: 12px 35px 10px 20px

      &--is-attaching
        opacity: 0.75

      &--is-deleting
        opacity: 0.5
        pointer-events: none

    .document-menu
      top: 50%
      margin-top: -14px

  h6
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
