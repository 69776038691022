$avatar-color-pool: (
  #1997BF
  #0A50A1
  #0E1877
  #63B6CF
  #426EA1
  #909090
  #6E6B6A
  #4B4A48
  #7C6AAE
  #68549F
  #74328E
  #922D8E
  #5CAEA3
  #358F8A
  #075F69
)
