.view-content-container
  background-color: $app-background

  &--fullscreen
    position: absolute
    top: 0
    left: 0
    z-index: 99
    height: 100%

.document-container
  position: relative

  .document-menu
    position: absolute
    right: 15px
    top: 15px
    z-index: 100

  .card-title
    padding-right: 15px

  .menu-toggle
    display: flex
    align-items: center
    padding-bottom: 15px
    line-height: 25px
    height: 30px
    color: #c9c7c7
    font-size: 1.8rem
    cursor: pointer
