$modal-padding: 1.25rem 2rem

.email-address-modal
  .modal-header
    flex: initial
    padding: $modal-padding

    .modal-title
      font-size: 1.1rem

  .modal-body
    font-size: .9rem
    padding: $modal-padding

    .form-block
      .form-field
        padding: .8rem 1rem
        font-size: .9rem

      .email-address-wrapper
        position: relative

        button
          border: none
          border-radius: 3px
          position: absolute
          right: 1rem
          top: 50%
          text-transform: capitalize
          transform: translateY(-50%)

    a
      font-weight: 500

  .modal-footer
    padding: $modal-padding
