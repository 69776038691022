.card-deck
  &--list
    @media (min-width: $break-small)
      .card-item
        width: 100%
        margin:
          left: 0
          right: 0

        @media (max-width: $break-semilarge)
          &:nth-of-type(2n+1)
            margin-right: 0

        @media (max-width: $break-semilarge)
          &:nth-of-type(2n)
            margin-left: 0

        .card
          align-items: center
          flex-direction: row
          justify-content: space-between
          height: auto

          &-title
            text-align: left

            @media (max-width: $break-medium)
              &
                width: calc(100% - 220px)

        .member-list
          display: inline-flex
          margin-right: 20px

          .user-avatar-holder
            vertical-align: top

          .user-avatar
            padding:
              top: 0

          .manage-users-small
            margin-top: 0
