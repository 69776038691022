.plate
  @extend .card

  padding: 0
  margin-top: 2rem
  margin-bottom: 2rem

  @include media-breakpoint-down(sm)
    margin-top: 1rem
    margin-bottom: 1rem

  .plate-section
    padding: 1.25rem
    border-bottom: 1px solid #e3e8ef

    &:last-child
      border-bottom: none

  .form-main-controls
    margin-top: 0
