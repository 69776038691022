html, body
  height: 100%
  width: 100%

body
  display: flex
  flex-flow: row nowrap
  background-color: $app-background
  -webkit-overflow-scrolling: touch
  min-width: 360px
  align-items: stretch

  &:not(.documents)
    @include media-breakpoint-down(sm)
      flex-direction: column

  &:not(.no-sidebar)
    overflow: hidden

.v-full
  height: 100%
  &.epicenter
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center

.fluid-container
  display: flex
  flex-direction: column
  overflow: auto
  -webkit-overflow-scrolling: touch
  flex: 1 1 auto
  height: 0
  z-index: 0

  .container
    flex: 1 1 0
    width: 100%

  &.discussion-page
    overflow: hidden

.narrow-container
  max-width: 560px

@mixin sidebar-visible
  margin-left: 0
  .icon-arrow-left-circled
    display: block

@mixin sidebar-hidden
  margin-left: -$sidebar-width
  .icon-arrow-left-circled
    display: none

aside.sidebar
  flex: 0 0 $sidebar-width
  @include sidebar-hidden
  .with-transition &
    transition: margin-left .5s ease
  @media (max-width: $break-semilarge)
    @include sidebar-visible
  @include media-breakpoint-down(sm)
    position: absolute
    flex: 1 0 100%
    width: 100%
    z-index: 3
    top: $header-mobile-size
    bottom: $header-mobile-size
    body.chats &,
    body.profile &,
    body.starred_items &
      bottom: 0
  body.no-sidebar &
    @include sidebar-visible
    @media (max-width: $break-semilarge)
      // same as "2 cards" breakpoint
      @include sidebar-hidden
    @include media-breakpoint-down(sm)
      @include sidebar-visible
      display: none

section.content
  flex: 1 1 100%
  width: 100%
  min-width: 0
  display: flex
  flex-direction: column
  @include media-breakpoint-down(sm)
    body:not(.chats):not(.profile):not(.starred_items):not(.authentication):not(.out-of-scheme) &
      padding-bottom: $header-mobile-size

.sub-content
  flex: 1 1 100%
  display: flex
  flex-direction: column

#desktop-view-flag
  @include media-breakpoint-down(sm)
    display: none

.universal-ai-sidebar-container
  display: contents
