.two-factor-modal
  .modal-content
    overflow: visible

    .modal-content-container
      overflow: visible
      padding: 1rem

  &__subheader
    margin-bottom: .75rem

  label
    display: block
    font-size: 87.5%
    margin-bottom: 0.25rem

  .verified-code-input
    width: 70%

  [data-behavior].check-mobile-link
    color: $link-color

    &:hover
      color: $link-hover-color

.two-factor-disable-modal
  .modal-dialog
    height: initial
    flex-direction: initial

  .modal-content-container
    padding: 1rem

  .actions
    justify-content: space-between

    @include media-breakpoint-down(xs)
      flex-direction: column

    .button
      display: inline-block
      text-align: center

      @include media-breakpoint-down(xs)
        margin: 1rem 0 0
        width: 100%

  p:last-child
    margin-bottom: 0
