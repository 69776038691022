.dropdown-menu
  .dropdown-item
    display: flex
    align-items: center
    padding: 0.25rem 0.75rem

    &.text-red
      color: $text-red-color
      opacity: .75

      &:hover, &:active, &:focus
        background-color: $dropdown-red-bg-color
        color: $text-red-color
        opacity: 1

      .dropdown-icon
        [class^="icon-"]
          color: $text-red-color
          opacity: .75

    .dropdown-icon
      display: flex
      justify-content: center
      width: 20px
      margin-right: 0.75rem
      color: $header-icons-color

      [class^="icon-"]
        font-size: 1.3em

      .icon-small
        font-size: 1.1em

      .icon-large
        font-size: 1.5em
