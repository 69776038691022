.badge
  display: inline-block
  line-height: 1
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  background-color: $badge-color
  padding: 4px 8px 3px
  color: white
  text-transform: uppercase
  border-radius: 0
  &:empty
    display: none
  .button &
    // Quick fix for badges in buttons
    position: relative
    top: -1px
  &.scheme-badge
    text-transform: none
    @each $color in $avatar-color-pool
      // colors starting from 0
      $i: index($avatar-color-pool, $color)-1
      &.badge-modifier-#{$i}
        background-color: $color
  &.badge-large
    border-radius: 4px
    font-weight: 500
    padding: 8px
  &#{&}-unpublished
    background-color: $unpublished-color
    &:hover
      background-color: $unpublished-color

  &#{&}-published
    background-color: $published-color
    &:hover
      background-color: $published-color

  &#{&}-updated
    background-color: $updated-color
    &:hover
      background-color: $updated-color

.plain-badge
  display: inline-block
  border: 1px solid #eaebef
  border-radius: 2px
  padding: 4px 8px 3px
  font-size: 12px
  line-height: 1
  background-color: transparent
  color: $notification-icon-color
  white-space: nowrap
