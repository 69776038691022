.fr
  &-box
    flex: 1 1 auto
    &.fr-basic
      .fr-element
        line-height: 1.5
        font-size: 1rem
        padding: 1rem
        color: $input-text-color !important
      .fr-wrapper
        border: none
        border-radius: 0 0 10px 10px

    .fr-quick-insert
      .fr-floating-btn
        display: flex
        justify-content: center
        align-items: center

        svg
          margin: 0

    .fr-wrapper
      @include media-breakpoint-down(md)
        .modal &
          border: none
          border-bottom: 1px solid #efefef
          border-radius: 4px 4px 0 0

      .fr-element.fr-view
        max-height: 100%
        height: 100%

        a.breadcrumb-link
          color: $link-color
          font-size: .75rem
          font-weight: 500

        @include media-breakpoint-down(md)
          .modal &
            border: none
            font-size: 16px
            padding: 16px
            padding-bottom: 25px !important

        .joint-space &
          @include media-breakpoint-up(md)
            box-shadow: inset 0 0 0px $shared-space-border $shared-space-color

    .second-toolbar, .fr-second-toolbar
      display: none

.fr-toolbar
  border: none !important

  .modal &
    display: none

  &.fr-toolbar-open
    padding-bottom: 0px

  .fr-command.fr-btn
    margin: 0

  .fr-more-toolbar
    &.fr-expanded
      height: 40px

  .fr-btn-grp
    margin: 0 12px

    &:first-child
      margin-left: 0
      margin-right: 0

    &.fr-float-left + .fr-float-right
      margin-right: 0

    @include media-breakpoint-down(md)
      // moreMisc button group
      &.fr-float-right + .fr-float-right
        display: none

    .message-block &
      margin-left: 7px
      margin-right: 7px

.fr-qi-helper
  a.fr-btn.fr-floating-btn
    line-height: 1.5

.fr-view
  blockquote
    margin: 0 0 0 0

.second-toolbar, .fr-second-toolbar
  .modal &
    display: none

.fr-fullscreen-wrapper
  background: #FFF

.fr-btn-grp:has(button[data-cmd*="openaiButton"])
  margin-left: 0
  margin-right: 0

.fr-toolbar
  .icon-openai
    color: white !important
    background: #000000
    border: 1px solid #000000
    border-radius: 4px
    font-size: 10px
    padding: 6px 0
    width: 27px !important
