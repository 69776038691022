$simple-badge-size: 8px

%simple-badge-circle
  content: ''
  position: absolute
  display: block
  left: 100%
  bottom: 100%
  width: $simple-badge-size
  height: $simple-badge-size
  margin:
    left: calc(-1 * $simple-badge-size / 2)
    bottom: calc(-1 * $simple-badge-size / 2)
  background-color: $notification-badge-warning-color
  border-radius: 100%
  font-size: 1px
  line-height: 1px

%actionable-badge-circle
  background-color: $notification-badge-pending-color
  width: $simple-badge-size * 1.25
  height: $simple-badge-size * 1.25

.unread
  position: relative

  &::before
    z-index: 9
    @extend %simple-badge-circle

  .sidebar-menu &
    &::before
      left: inherit
      right: 11px
      top: 50%
      bottom: initial
      transform: translateY(-50%)
      margin: 0

  &.sidebar-toggle:has(.icon-arrow-right-circled)
    &::before
      margin:
        left: -$simple-badge-size
        bottom: -$simple-badge-size

  &.sidebar-toggle:has(.icon-hamburger), &.navigation-menu-link
    &::before
      margin:
        left: -$simple-badge-size * 1.5
        bottom: -$simple-badge-size * 1.5
      .user-menu &
        margin:
          left: -$simple-badge-size * 2.2
          bottom: -$simple-badge-size * 2.2
        @include media-breakpoint-down(md)
          order: 1
          position: static
          margin: 0 0 0 .5rem

  &.notification-badge
    &::before
      display: none

  &.sidebar-base-nav-link
    &::before
      margin:
        left: -20px
        bottom: -17px

  body.joint-space &
    &.sidebar-toggle
      &::before
        @include media-breakpoint-up(md)
          border: 1px solid white
    &.header-nav-item
      &::before
        @include media-breakpoint-up(md)
          border: 1px solid white

  &.navigation-menu-link
    &::before
      @include media-breakpoint-only(md)
        margin-left: 0
      @include media-breakpoint-down(sm)
        margin:
          left: -$simple-badge-size * 1.8
          bottom: -$simple-badge-size * 1.8

.pending
  @extend .unread

  &::before
    @extend %actionable-badge-circle

.mention
  @extend .unread

  &::before
    @extend %actionable-badge-circle
