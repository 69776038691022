// serves for displaying icon only on desktop view but icon+text on devices

.iconic
  &-caption
    display: none
    @include media-breakpoint-down(md)
      display: initial
  .notification-badge.counter
    margin-right: .5rem
    @include media-breakpoint-down(md)
      order: 1
      margin-left: 1rem
