$column-padding: 3rem
$column-padding-small: 1rem

.centered-container
  padding-bottom: $spaceSmall

  .col
    width: 75%
    margin: 30px auto 0
    background: #FFF
    padding: $column-padding $column-padding #{$column-padding - $column-padding-small}
    border-radius: 5px
    box-shadow: 2px 2px 5px rgba(115, 131, 158, 0.15)

    +screen(small-only)
      padding: $column-padding-small
      width: 100%

    hr
      width: calc(100% + #{$column-padding*2})
      margin-left: -$column-padding

      +screen(small-only)
        width: calc(100% + #{$column-padding-small * 2})
        margin-left: -$column-padding-small
