.meeting-pack-form
  .form-blocks
    .form-block
      &.title
        width: 100%
        margin-right: $spaceLarge

      &.duration
        width: 25%
        min-width: 225px

        @include media-breakpoint-down(md)
          width: 100%

      .field
        position: relative

        .form-field.time-picker
          background: white

        .time-picker-clear
          top: 12px
          right: 6px
          font-size: 20px
          position: absolute
          cursor: pointer

  .fr-box
    border: 1px solid #ced4da
