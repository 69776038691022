.people-list
  padding-top: 0

  .card
    margin: 0
    padding: 11px 20px

    &.is-selected
      background-color: #f2f8ff

    &.horizontal
      .card-controls
        display: flex
        justify-content: flex-end
        flex-basis: 65px

  &-actionable
    .card
      @include media-breakpoint-down(sm)
        padding: .75em 0

  .card-auxiliary
    align-items: center
    justify-content: flex-end

  .card-status-wrapper
    text-align: center
    width: 70px
    @include media-breakpoint-down(sm)
      flex-basis: 30px
      text-align: right
      width: auto

  .card-status-label
    @include media-breakpoint-down(sm)
      width: 12px
      height: 12px
      border-radius: 50%

      span
        display: none

  .card-message-action
    color: $sidebar-active-color
    &__complete
      color: $link-active-color
    &-status
      display: flex
      align-items: center
      white-space: nowrap

      [class^="icon-"]
        font-size: 1.75rem

  .checkbox
    display: inline-flex
    margin-bottom: 0

    > label
      margin-bottom: 0
      &::before
        margin-right: 0

  &-account-title
    padding-top: 1rem

  & + &-account-title
    padding-top: 2.5rem
