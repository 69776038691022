.meeting-pack-form
  transition: width .5s ease
  @include media-breakpoint-down(sm)
    height: auto

  .form-blocks
    display: flex
    justify-content: center
    @include media-breakpoint-down(md)
      display: block

    .form-block
      width: 100%
      margin-right: $spaceLarge

      &:last-child
        margin-right: 0

      .form-field.date-time-picker
        background: white
        cursor: grab

  .form-footer
    margin: $spaceMedium
    display: flex
    justify-content: center

  .checkbox
    margin-bottom: .25rem

    &.disabled
      pointer-events: none
      color: $icon-color

    & > label::before
      height: 20px
      margin-top: 1px
      width: 20px

    & > label::after
      top: 1px
      left: -2px

  .text-notification
    color: $text-red-color
    font-size: 14px
    font-weight: 500

  .copy-information-checkbox
    display: none

    &:not(:checked)
      & + div .button:last-child
        @extend .is-outline-primary
    &:checked
      & + div .button:first-child
        @extend .is-outline-primary

  .copy-information-section
    margin-top: 2rem

  .meeting-pack-name
    resize: none
    padding: $input-padding
    min-height: 3rem
