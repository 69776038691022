.rotatable-toggle
  @extend .button
  @extend .is-outline-primary
  @extend .is-round-micro
  @extend .rotatable
  flex: none
  margin-right: 0.2rem
  margin-left: 0.5rem
  justify-content: center
  position: relative
  &:active,
  &:focus
    box-shadow: none
    color: white
  @include media-breakpoint-down(md)
    top: 0
  @include media-breakpoint-down(sm)
    width: 17px
    height: 17px

