.notification-badge
  padding: 3px 3px
  background-color: $notification-badge-color
  border-radius: 4px
  font-size: 12px
  color: $notification-icon-color
  text-align: center
  min-width: 1.5rem
  display: inline-block
  [class^="icon-"], [class*=" icon-"]
    font-size: 1.3em
    vertical-align: middle
  &.counter
    background-color: $notification-badge-warning-color
    color: white
  &.unsigned
    background-color: $notification-badge-pending-color
  &.pending
    background-color: $notification-badge-pending-color
  &.mention
    background-color: $notification-badge-mention-color
  &.muted
    background-color: $muted-badge-color
    color: white
    span
      font-size: 1em

  & + &
    margin-top: .3rem
