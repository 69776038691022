%text-input-base
  background: white
  box-shadow: $input-shadow
  border-radius: 3px
  color: $input-text-color !important
  box-shadow: none
  border: 1px solid $border-control-color
  padding: $input-padding
  line-height: 1.3
  &::placeholder
    color: $input-placeholder-color

input
  &[type="text"],
  &[type="tel"],
  &[type="password"],
  &[type="email"]
    @extend %text-input-base

  &.form-control,
  &.form-field
    height: auto

select.form-field
  @extend %text-input-base
  height: auto !important
  padding-right: 25px
  background: white url('~images/select-arrow.svg') no-repeat right 10px center
  appearance: none

  &::-ms-expand
    display: none

.discussion-input
  position: relative
  width: 100%
  touch-action: manipulation
  overflow: auto
  // to preserve space for attach-document-link
  @include media-breakpoint-down(sm)
    padding: .5rem .75rem
    line-height: 1.2rem

textarea
  @extend %text-input-base
  width: 100%
  resize: vertical
  min-height: 5rem

// https://issues.chromium.org/issues/40797488
// https://stackoverflow.com/questions/68483618/my-html-input-field-is-readonly-by-default-since-the-last-chrome-update
input:not[type="checkbox"], textarea /*, [contenteditable]*/
  &:read-only
    cursor: not-allowed

[contenteditable="true"]:empty:before
  content: attr(placeholder)
  display: block
  color: #CCC
