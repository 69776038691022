.subheader
  &-nav
    font-family: 'Space Grotesk', sans-serif
    position: relative
    flex: 0 0 auto
    display: flex
    min-height: 68px
    border-bottom: 2px solid $separator-color
    padding: 9px 1rem
    align-items: center

    @media (min-width: $break-semilarge)
      min-height: 74px

    &.hide-on-mobile
      @include media-breakpoint-down(sm)
        display: none

    &-grid
      display: grid
      display: -ms-grid
      grid-template-columns: 240px 1fr 240px
      @media (max-width: 1230px)
        grid-template-columns: 50px 1fr 50px
      &.groups-list-nav
        @media (min-width: 768px) and (max-width: 1230px)
          grid-template-columns: 110px 1fr 110px
      &.subheader-nav-component
        grid-template-columns: 200px 1fr 250px
        @include media-breakpoint-down(sm)
          grid-template-columns: 50px minmax(0, 1fr) 75px
    h5
      font-size: 1.2rem
      @media (max-width: 1230px)
        font-size: 1rem

    h6
      font-size: 1.05rem
      color: $subheader-link-color

    .extended-member-list
      h6
        margin-right: 1rem
        line-height: $avatar-height
        @include media-breakpoint-down(sm)
          display: none
      .button
        color: #4f4f57
        border: none

      .button:not(:disabled):not(.disabled):active
        box-shadow: none
        background-color: #a5a5ad

    body.joint-space &
      @include media-breakpoint-down(sm)
        box-shadow: inset 0 #{$shared-space-border} 0 0 $shared-space-color
        padding:
          left: 1rem
          right: 1rem

    .container
      @include media-breakpoint-down(xs)
        padding:
          left: 0
          right: 0

    .text-separator
      @extend %icon, .icon-dropdown-arrow
      color: $subheader-link-color
      font-size: 1.75rem
      max-height: 1.5rem
      max-width: 1.5rem
      transform: rotate(-90deg)
      transform-origin: 60% 60%
      @media (max-width: 1230px)
        transform-origin: 57% 57%

  &-title
    -ms-grid-column: 2
    grid-column: 2
    -ms-grid-row: 1
    grid-row: 1
    -ms-grid-row-align: center
    display: flex
    flex: 1
    min-width: 0
    align-items: center
    justify-content: center

    &-prefix
      font-size: .7em
      font-weight: normal
      padding-top: 3px
      margin-right: $spaceXSmall

    &.group-title
      [class*='icon-bell-crossed-filled']
        font-size: .75em
        margin-right: 5px
        vertical-align: middle

    &.is-complex
      flex-direction: column

      h5
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        text-align: center
        line-height: 1.5

    &:not(.is-complex)
      h5
        @media (max-width: 1230px)
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

    &-section
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      @include media-breakpoint-down(sm)
        white-space: normal

      &:not(:last-of-type),
      &.group-name,
      &.parent-item-name
        @include media-breakpoint-down(sm)
          display: none

      & + .text-separator
        @include media-breakpoint-down(sm)
          display: none

      .text-separator
        @media (max-width: 1230px)
          font-size: 1.2rem

  &-control
    display: inline-flex
    @media (max-width: 1230px)
      border: none
      margin:
        left: -0.75rem
        right: -0.75rem
      font-size: 18px

    .control-text
      margin-left: 0.5rem
      @media (max-width: 1230px)
        display: none

    &.dropdown-toggle
      @include media-breakpoint-down(sm)
        margin-right: -1rem
        &::after
          margin-left: 0

  &-link
    -ms-grid-column: 1 //old IE syntax for IE11 only
    grid-column: 1
    -ms-grid-row-align: center
    @include media-breakpoint-down(sm)
      display: none

    .subheader-control
      &::before
        content: "\2190"

  &-actions
    -ms-grid-column: 3 //old IE syntax for IE11 only
    grid-column: 3
    -ms-grid-row-align: center
    text-align: right

    .subheader-control
      height: 38px
      color: #4f4f57
      border: none
      margin-right: 2px


    .subheader-control.button:not(:disabled):not(.disabled)
      &:active, &:focus
        background-color: #a5a5ad
        color: white
        border: none

    .discussions & .dropdown-menu
      min-width: max-content

  &-form
    display: flex
    align-items: center

@media print
  .subheader-nav
    display: none
