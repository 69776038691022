.member-list
  @extend .flex-row
  text-align: center
  justify-content: center

  .flex-member-list
    display: flex
    max-height: $avatar-height + .5rem
    flex-flow: row wrap

.extended-member-list
  text-align: center
  margin-top: .5rem
  display: inline-flex

  .user-avatar
    padding-top: 0

  .round-toggle.initials
    margin-right: 0.1rem

  .flex-member-list
    display: flex
    @include media-breakpoint-down(sm)
      .user-avatar-holder
        &:nth-child(n+5)
          display: none

.members-modal
  .round-toggle.initials
    margin-right: $avatars-spacing

.group-settings
  top: 8px
  left: 8px
  font-size: 0.75rem
  color: $avatar-color
