body
  &.offline
    a:not(.saved-offline),
    button:not(.saved-offline):not(.notyf__dismiss-btn)
      opacity: 0.4
      pointer-events: none

    .agenda-item-container.sortable-item
      pointer-events: none

    .agenda-item-row .actions-section
      display: none


.saved-offline-icon
  background-color: $success-color
  color: #FFF
  width: 18px
  height: 18px
  font-size: .45rem
  display: inline-block
  position: absolute
  border-radius: 50%
  top: 6px
  right: 30px
  margin-left: 3px
  padding-top: 4px
  text-align: center

