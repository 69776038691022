.edit-agenda-items-modal
  .modal-content
    > form
      height: 100%

      .modal-content-container
        max-height: 70vh

        @include media-breakpoint-down(sm)
          max-height: 65vh

  .modal-stripe
    width: 100%
    .alert
      &.is-admin
        background-color: $account-admin-color
      &.is-guest
        background-color: $account-guest-color
  table
    td
      border-top: none

      .checkbox
        label::before
          margin-right: 0

  .role-type
    display: flex
    justify-content: space-between
    padding: 2rem 3rem

    .select
      width: 100%

      label
        color: $text-muted-color

.add-members-modal
  .form-block
    margin-bottom: 0

    label
      font-size: .85rem

    .form-field
      padding: .5rem .8rem

  .add-another-member-form-link
    display: inline-block
    padding-left: 1.25rem
    padding-top: 1rem

  .modal-dialog
    display: block
    max-height: calc(100% - 30px * 2)
    height: auto

    .card.additional
      border-top: 1px solid #e9ecef

      label
        display: none
        @media (max-width: $break-semilarge)
          display: block

.remove-another-member-form-link
  margin-right: auto

.members-form-list-row-wrapper
  align-items: flex-end
