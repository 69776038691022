.sign-in-container
  background: #FFF
  padding: 3rem 5rem 2.5rem

  +screen(small-only)
    padding: 1rem

  .wide-button
    width: 100%
    display: inline-block
    text-align: center

  label
    color: $text-muted-color
    font-weight: 500

    &.smaller-label
      font-size: smaller

  .sign-in-section
    padding: 1.25rem 0
    border-bottom: 1px solid #e3e8ef


  .sign-in-header
    color: $heading-color
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif
    margin-bottom: 1.75rem

  .form-block
    margin-bottom: 2rem

  .actions
    display: block

    .button
      font-size: 1.15rem
      font-weight: 500
      display: block
      margin: 0
      padding: .75rem
      width: 100%

      +screen(small-only)
        font-size: 1rem

    .button + .button
      margin-top: .5rem

  .sign-in-issue
    font-size: .9rem
    font-weight: 600

  .no-code-section
    font-size: .9rem

  .security-copy
    margin-top: 2.5rem
