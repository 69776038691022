$toggler-stripe-height: 4px
$toggler-stripe-height-mobile: 3px

.sidebar-toggle
  .sidebar &
    @include media-breakpoint-up(md)
      border-left: none

  .header &
    @include media-breakpoint-up(md)
      order: 0
      border:
        right: 1px solid $avatar-color
        left: none

    &.show-sidebar
      @media (min-width: $break-semilarge + 1px)
        width: 0
        padding: 0
        border-right: none
        overflow: hidden

    &:not(.show-sidebar)
      @media (max-width: $break-semilarge)
        width: 0
        overflow: hidden
      @include media-breakpoint-down(sm)
        width: $header-mobile-size

    body.joint-space &
      &.show-sidebar
        @media (min-width: $break-medium) and (max-width: $break-semilarge)
          color: $shared-space-text-color
          border-color: $shared-space-text-color

      &:not(.show-sidebar)
        @media (min-width: $break-semilarge)
          color: $shared-space-text-color
          border-color: $shared-space-text-color
