.progress
  max-width: 600px
  margin-top: 0.2em

.progress .bar
  height:  1.4em
  padding-left: 0.2em
  color:   white
  display: none

  &.activated
    background: #007bff
    display: block
    width: 0%
