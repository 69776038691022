.modal
  form
    margin-block-end: 0

  &-dialog
    display: flex
    height: calc(100% - 30px * 2)
    flex-direction: column
    margin-bottom: 0
    &.modal-dialog-centered
      flex-direction: initial
    .flex-modal &
      max-width: 80vw
      margin:
        left: auto
        right: auto
    .contacts &
      height: initial
    > form
      display: flex
      flex-direction: column
      flex: 1 1 auto
      height: 100%
  &-header
    background-color: $card-background-color
    flex: 1 0 content
  &-title
    .line
      display: block
      overflow: hidden
      text-overflow: ellipsis
      color: $message-text-color
      white-space: nowrap
  &-content
    display: flex
    flex-direction: column
    flex: 0 1 auto
    min-height: 20%
    max-height: 100%
    flex-flow: column nowrap
    overflow: hidden
    padding: 0
    &.upload-document
      height: 600px
      @include media-breakpoint-down(sm)
        height: 90vh
    .flex-modal &
      flex: 1 1 auto
      height: 100%
    &-header
      @extend .modal-header
      flex: 0 0 auto
    &-subheader
      box-shadow: $header-shadow
      padding: $spaceSmall
    &-container
      flex: 0 1 auto
      max-height: initial
      height: 100%
      overflow: auto
      -webkit-overflow-scrolling: touch
    &-footer
      @extend .modal-footer
      flex: 0 0 auto
      margin-top: auto
      justify-self: flex-end
  &#attach-document-modal
    .modal-content
      height: 600px
      @include media-breakpoint-down(sm)
        height: 90vh

      .attach-file-tab
        display: none
        height: calc(100% - 44px)
        min-height: 250px

  &#new-message-modal
    font-size: 1rem
    flex-flow: column
    justify-content: flex-end
    .modal-content
      .joint-space &
        border: $shared-space-border solid $shared-space-color
    .modal-dialog
      height: auto
    .modal-content
      height: 97dvh
      max-height: 97dvh
      overflow: visible
      @include media-breakpoint-up(sm)
        height: 90dvh
        max-height: 90dvh

    .modal-title
      font-size: 100%
      color: $subheader-link-color
      .line
        font-size: 1.1rem
    .discussion-input
      max-height: 16vh
      padding-right: .25rem .7rem
      transition: height .5s ease
      &:focus, &:active
        outline: none
        max-height: 16vh
    .message-validation
      background-color: $card-background-color
      padding: .5rem .7rem
    .buttons
      background-color: $card-background-color
      padding: .5rem .7rem
      border-radius: 0 0 .3rem .3rem
      display: flex
      justify-content: flex-end
      > .button
        &:first-child
          margin-right: auto
    .attached-document
      overflow-y: scroll
      max-height: 75px
      height: 100%
      background-color: $card-background-color
      padding: .5rem .7rem
      .card
        box-shadow: none
        border: none
        padding: .5rem 2rem .5rem .5rem
        display: inline-block
        width: 100%
        margin: 0
        .document-menu
          right: 9px
          top: 3px
          + a
            pointer-events: none
        .card-title-secondary
          display: none
        .card-title
          font-size: .9rem
          margin-top: 0 !important
          h6
            font-size: inherit
    #mobile-message-form
      height: 100%
      display: flex
      flex-direction: column

      .fr-box
        overflow-y: scroll

      .fr-wrapper
        height: 100%

  &.alternate
    .modal-content
      background-color: $app-background

  &.top-layer
    z-index: 1060

  &-notification
    padding: 15px 20px
    border-bottom: 1px solid #e9ecef
    background-color: #f2f8ff
    font-size: 14px

.modal-backdrop
  &.new-message-action-modal-backdrop
    z-index: 1050
