.agenda-items-modal
  .card-auxiliary
    display: flex
    align-items: center

    .account-role-badge
      margin-right: 1rem

.agenda-items-manage-list
  .agenda-item-attendee-title
    border-top: 1px solid #e3e7ed
    border-bottom: 1px solid #e3e7ed
    font-size: 0.875rem
    text-transform: uppercase
    text-align: center
    padding: 0.5rem 0

  .card.is-user
    padding: .5rem 0

    .card-controls
      flex-basis: initial

  .modal-content-footer
    padding-top: .65rem

.warning-text
  font-size: .85rem
  margin-top: .25rem

  &.attendees-notification
    text-align: center

  .danger-text
    color: $text-red-color
  .success-text
    color: $shared-space-color

.manage-attendees-form
  .card-controls
    margin-left: 1.5rem
    @include media-breakpoint-down(xs)
      margin-left: .5rem

    .checkbox > label::before
      margin-right: 0

  .account-role-badge
    @include media-breakpoint-down(xs)
      font-size: .7rem
      padding: 2px 4px

.account-role-badge
  align-items: center
  border-radius: 5px
  color: $heading-color
  display: flex
  font-size: .85rem
  padding: 4px 6px


  [class^="icon-"]
    margin-right: .25rem

  &.is-admin
    background-color: $account-admin-color
  &.is-guest
    background-color: $account-guest-color

.publish-status-badge
  align-items: center
  justify-content: center
  border-radius: 5px
  color: white
  display: flex
  font-size: .85rem
  padding: 4px 6px
  white-space: nowrap

  &.is-admin
    background-color: $account-admin-color
    color: $heading-color
  &.preview
    background-color: $publish-preview-color
  &.unpublished
    background-color: $light-red
  &.published
    background-color: $published-color
