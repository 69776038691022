.main-menu
  justify-content: center
  display: flex
  flex-flow: row nowrap
  margin:
    left: auto
    right: auto
    bottom: 0
  padding-left: 0
  height: 100%

  @include media-breakpoint-down(sm)
    background-color: white
    box-shadow: 0 0 4px #bbc1cb
    min-height: $header-mobile-size
    height: initial
    padding: 0
    position: fixed
    bottom: 0
    left: 0
    z-index: 3
    width: 100%
    margin-bottom: 0
    transform: none
    justify-content: space-around
    [class*='icon-']
      font-size: 22px
      + .menu-link-caption
        display: none

    body.joint-space &
      background-color: $shared-space-color
      box-shadow: none

    body.is-pwa.is-iphone &
      padding-bottom: 1.25rem

  .navigation-menu-item
    font:
      weight: 500
    margin-right: 1rem
    @include media-breakpoint-down(sm)
      margin-right: 0

  .navigation-menu-link
    display: flex
    flex-direction: row
    position: relative
    color: $heading-color
    height: 100%
    @include media-breakpoint-down(md)
      padding-left: 0
      padding-right: 0
    @include media-breakpoint-down(sm)
      padding: .75rem 1rem

    &:last-child
      margin-right: 0

    &:hover
      color: $icon-color
      transition: color .2s linear

    &.highlighted
      color: $link-color

      [class^="icon-"], [class*=" icon-"]
        color: inherit

      &::after
        position: absolute
        top: calc(100% - 5px)
        left: 50%
        transform: translateX(-50%) rotate(-45deg)
        width: 12px
        height: 12px
        background: white
        border: 1px solid #dbe1e7
        border-width: 0 0 1px 1px
        content: ''
        @include media-breakpoint-down(sm)
          top: auto
          bottom: calc(100% - 5px)
          border-width: 1px 1px 0 0

    [class^="icon-"], [class*=" icon-"]
      text-align: right
      padding-right: .5rem
      color: $icon-color
      @include media-breakpoint-down(sm)
        padding-right: 0

    .icon-star-filled
      color: $starred-message-star-color

    body.joint-space &
      color: $shared-space-text-color

      &:hover
        color: white

      &.highlighted
        color: white

        &::after
          margin-top: -1px
          background-color: $shared-space-color
          border-color: $shared-space-color
          @include media-breakpoint-down(sm)
            margin-top: 0
            margin-bottom: -1px

        [class^="icon-"], [class*=" icon-"]
          color: white

      &.unread::before
        border: 1px solid white

      &.pending::before
        border: 1px solid white

      [class^="icon-"], [class*=" icon-"]
        color: inherit

.hidden-on-large-screens
  @media (min-width: $break-semilarge + 1)
    display: none
