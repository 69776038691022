.badge
  padding: $badge-padding-y $badge-padding-x
  font-size: $badge-font-size
  font-weight: $badge-font-weight
  @include border-radius($badge-border-radius)
  @include badge-variant($primary)

  &.is-success
    @include badge-variant($success)

  &.is-danger
    @include badge-variant($danger)

  &.is-warning
    @include badge-variant($warning)

  &.is-info
    @include badge-variant($info)

  &.is-rounded
    padding-right: $badge-pill-padding-x
    padding-left: $badge-pill-padding-x
    @include border-radius($badge-pill-border-radius)
