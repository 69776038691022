$dismiss-button-size: 20px

.notyf
  &__icon
    display: none

  &__toast
    max-width: 550px

    &--error
      background: $light-red !important

      .notyf__wrapper
        background: $light-red !important

  & &__wrapper
    padding-right: 75px

  &__dismiss
    & &-btn
      background-color: transparent
      border: 2px solid #FFF
      border-radius: 50%
      opacity: 1
      padding: 0
      position: absolute
      top: calc(50% - (#{$dismiss-button-size}/2))
      right: 50%
      width: $dismiss-button-size
      height: $dismiss-button-size

      &:hover
        opacity: .7

      &:before, &:after
        top: calc(50% - 6px)

