$checkbox-box: 25px

.checkbox
  position: relative
  display: flex
  flex-flow: row nowrap
  cursor: pointer
  font-size: 1em
  margin-bottom: 1.2rem
  padding-left: 0
  &:hover
    opacity: .8
  > label
    width: 100%
    cursor: inherit
    display: flex
    flex-flow: row nowrap
    justify-items: center
    user-select: none
    &::before, &::after
      content: ""
      display: block
    &::before
      width: $checkbox-box
      height: $checkbox-box
      background-color: white
      border-radius: .2rem
      border: 1px solid $border-control-color
      margin:
        // small position adjustment
        top: -2px
        right: 12px
    &::after
      position: absolute
      font:
        family: 'knowa-icons'
        size: 24px
      line-height: 24px
      content: $icon-done
      color: $success-color
      top: 0
      left: 1.5px
      // small position adjustment
      margin-top: -1px
      opacity: 0
      transition: opacity .1s ease-in
  > input[type="checkbox"]
    display: none
    &:checked + label
      &::after
        opacity: 1
