.agenda-items-header
  margin-bottom: $spaceSmall

  .is-mobile
    display: block

    @include media-breakpoint-up(md)
      display: none

  .is-desktop
    display: none

    @include media-breakpoint-up(md)
      display: flex

  .list-heading
    width: 100%
    justify-content: space-between

  .dropdown-heading-actions
    .button
      display: block

  &.fixed
    padding: 8px 120px
    display: flex
    justify-content: center
    margin-bottom: 0

    @include media-breakpoint-down(sm)
      padding: 8px 15px

    &.scrolling
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.09)

    .list-heading
      margin-bottom: 0
      max-width: 1335px

