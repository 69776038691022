.button
  @extend .btn
  @extend .btn-primary
  white-space: nowrap

  &.is-success
    @extend .btn-success

  &.is-warning
    @extend .btn-warning

  &.is-danger
    @extend .btn-danger

  &.is-secondary
    @extend .is-secondary

  &.is-info
    @extend .btn-info

  &.is-light
    @extend .btn-light

  &.is-dark
    @extend .btn-dark

  &.is-outline-primary
    @extend .btn-outline-primary
    background-color: transparent
    &:focus
      color: $link-color

  &.is-outline-success
    @extend .btn-outline-success

  &.is-outline-warning
    @extend .btn-outline-warning

  &.is-outline-danger
    @extend .btn-outline-danger

  &.is-outline-secondary
    @extend .btn-outline-secondary

  &.is-outline-info
    @extend .btn-outline-info

  &.is-outline-light
    @extend .btn-outline-light

  &.is-outline-dark
    @extend .btn-outline-dark

  &.is-outline-large
    +button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $input-btn-line-height-lg, $btn-border-radius-lg)

  &.is-small
    +button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $input-btn-line-height-sm, $btn-border-radius-sm)

  &.is-block
    @extend .btn-block
