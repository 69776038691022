.card
  background-color: $card-background-color
  box-shadow: $standard-block-shadow
  border-radius: $card-border-radius
  border: none
  > p
    margin-bottom: 0
    line-height: 42px
    @include media-breakpoint-down(sm)
      line-height: 1
  &-status
    @extend %round-toggle
    @extend %round-toggle-action
    color: $success-color
    border-color: $success-color
    position: absolute
    top: initial
    bottom: .5rem
    right: .5rem
    &.icon-done
      &::before
        position: relative
        top: -2px
    &.unchecked
      display: none
  &-deck
    margin:
      top: 15px
      right: 0
      left: 0
    @include media-breakpoint-down(sm)
      margin-right: 0
    .discussions &
      padding-right: 15px
    &-caption
      flex: 0 0 100%
      margin: 0 15px 1rem
    .card
      width: 100%
      text-align: center
      height: 145px
      justify-content: space-around
      &:first-child
        margin-left: 0
      &:last-child
        margin-right: 0
      .schemes &
        height: 100%
      @include media-breakpoint-down(sm)
        height: auto
        margin-bottom: 0

      .card-title
        font-size: 1.1rem
        line-height: 1.3

      &.document-card
        padding: 1rem
        max-height: 130px
        text-align: left
        justify-content: flex-start
        transition: .2s ease-out
        color: $card-text-color

        .card-title h6
          font-size: 0.9rem
          max-width: calc(100% - 2.2rem)

  &.neutral
    background-color: transparent
    border: none

  &-item
    width: 32%
    margin: 0 1%
    padding-bottom: 1rem
    &:nth-of-type(3n)
      margin-right: 0
    &:nth-of-type(3n+1)
      margin-left: 0
    @media (max-width: $break-semilarge) // so that cards stand by 2 starting from 1100, onsidering sidebar eats horizontal space
      width: 49%
      &:first-child
        margin-right: 1%
      &:nth-of-type(2n)
        margin-left: 1%
        margin-right: 0
      &:nth-of-type(2n+1)
        margin-left: 0
        margin-right: 1%
    @include media-breakpoint-down(sm)
      width: 100%
      margin: 0
      &:nth-of-type(2n)
        margin-left: 0
        margin-right: 0
    .card-link
      top: 0.6rem
      right: 0.6rem

  &.is-user
    border: 0
    padding:
      left: 0
      right: 0
      bottom: 0
  &-text
    color: $card-meta-color
    font-size: .9rem
    margin-top: .5rem
    p
      margin-bottom: 0
  &-title
    overflow: hidden
    margin-bottom: 0
    h5, h6
      margin-bottom: 0
    &-secondary
      font-size: 0.75rem
      &:not(.text-link)
        color: $card-meta-color
      .document-card &
        margin-right: 3rem

    &__with-link
      margin-right: 1rem
      @include media-breakpoint-up(sm)
        max-width: 80%
  &-status-label
    flex: 0 0 auto
    padding: 1px 4px
    border-radius: 3px
    font-size: 0.75rem
    &.online
      background-color: $success-color
      color: white
    &.offline
      background-color: #edeef1
      color: #bec2cd
  &-document-icon
    color: rgba(0, 0, 0, 0.54)
    font-size: 2rem
    line-height: 0

  &.horizontal
    display: flex
    flex-flow: row nowrap
    justify-items: stretch
    align-items: center
    .card-controls, .card-mascot
      flex: 0 0 auto
    .card-controls
      flex-basis: 90px
      text-align: right
    .card-title
      display: flex
      flex-direction: column
      flex: 1 1 auto
    .card-title-primary
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    .card-auxiliary
      @extend .card-title-secondary
      flex: 0 0 auto
      padding-left: 15px
      display: flex
      justify-content: space-between

      .card-role
        margin-right: 1rem
  &.clickable
    cursor: pointer
    &:hover
      opacity: .75
  & + &
    margin-top: 1px
  &-list
    display: flex
    flex-flow: column nowrap
    justify-content: center
    overflow: hidden

    &.allow-scroll
      overflow: auto
    > p
      align-self: center
      margin-bottom: 0
      font-size: 90%
      color: $heading-color
    .card
      border-width: 0 0 1px
      border-radius: 0
      background-color: transparent
      box-shadow: none
      flex: 1 0 auto
      &:last-child
        border-bottom-width: 0
      + .card
        margin-top: initial
  &-notifications
    position: absolute
    top: 23px
    display: flex
    flex-flow: column nowrap
    height: 100%
    left: -11px

    .card-deck--list &
      top: 0
      justify-content: center

    @include media-breakpoint-down(sm)
      top: 20px
    .schemes &
      top: 14px
  .schemes &
    margin-bottom: 1rem

  & &-membership
    cursor: pointer

  &-link
    &:not(:hover)
      @extend .text-muted
    position: absolute
    top: 1rem
    right: 1rem
    display: none
    @include media-breakpoint-down(sm)
      display: block

.card:hover .card-link
  display: block


.account-wrapper
  padding: 1.5rem 2rem
  margin-bottom: 2rem
  @include media-breakpoint-down(sm)
    margin-bottom: 1rem
  &.is-connected
    position: relative
    border: $shared-space-border solid $shared-space-color

.account-wrapper-connector
  transform: translateY(-15px)
  text-align: center
  color: $shared-space-color
  @include media-breakpoint-down(sm)
    transform: translateY(-8px)

  &:last-child
    margin-bottom: 0
  h5
    font-size: 21px

.account-connected-title
  display: inline-block
  font-size: 14px
  font-weight: normal
  color: $shared-space-color

.user-wrapper
  flex: 0.33
