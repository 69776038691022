.delete-bottom-link
  color: $text-red-color
  opacity: .75
  display: flex
  align-items: center
  justify-content: center
  transition: all .2s linear
  margin-bottom: $spaceSmall

  &:hover, &:active, &:focus
    color: $text-red-color
    opacity: 1
