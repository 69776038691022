$checkbox-box: 25px

.radio
  @extend .checkbox
  > label
    &::before
      border-radius: 100%
    &::after
      font:
        size: 22px
  > input[type="radio"]
    display: none
    &:checked + label
      &::after
        opacity: 1
