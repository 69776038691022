.embed-plugin-popup
  padding: 10px

  .embed-buttons
    display: flex
    justify-content: space-between
    margin-top: 10px

    button
      margin: 0
      font-size: .9em
      padding: 2px 8px

  textarea
    padding: 5px
    min-width: 250px
