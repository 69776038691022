.meeting-pack-subheader
  .subheader-nav
    display: flex
    justify-content: space-between
    width: 100%

    .left-side,
    .right-side
      max-width: 250px
      width: 100%
      @include media-breakpoint-down(sm)
        max-width: 100px

      @include media-breakpoint-down(xs)
        max-width: 50px

    .center
      // hack for long texts with text-overflow: ellipsis;
      min-width: 0

  .subheader-title
    &.is-complex
      .parent-item-name
        max-width: 42%

      .subheader-title-prefix
        white-space: nowrap

  @media (min-width: $break-medium)
    .meeting-icon-tooltip
      display: none

  .meeting-name,
  .meeting-date
    display: none

    @media (min-width: $break-medium)
      display: block

      .text-x-small
        font-size: 13px

  .subheader-link
    display: none

    @include media-breakpoint-up(md)
      display: block

  @media (max-width: $break-medium)
    .pages-counter-text
      font-size: 13px
      white-space: nowrap

  @media (max-width: $break-small)
    .meeting-details > *
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      margin: 0 auto

  .beta-label
    font-size: 12px
    color: #181023
    background-color: #F8F8FE
    border: 1px #AFBCCE solid
    border-radius: 4px
    text-transform: uppercase
    display: flex
    padding: 2px 7px

.meeting-pack-attendees-subheader
  .subheader-nav.subheader-nav-grid
    grid-template-columns: 0 1fr 0
