.chats-subheader
  &.subheader-nav-grid
    @include media-breakpoint-down(sm)
      display: flex

  .container
    flex: none
    display: flex
    justify-content: space-between

.chat-card
  margin: 1rem 0

  &:hover
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)

.start-chat-button-text
  @include media-breakpoint-down(xs)
    display: none

    & + span[class^="icon-"]
      margin: 0
      font-size: 1.5rem

.chat-message-form
  .fr-box
    .fr-wrapper
      border: none
      border-radius: 4px
      box-shadow: inset 0 4px 4px -3px rgba(0, 0, 0, 0.2)

  .fr-toolbar, .second-toolbar, .fr-second-toolbar
    display: none

.chat-block
  @extend .form-block
  margin: 0
  padding: 0
  // flex: 1 0 $chat-box-height
  flex: 1 0 auto
  box-shadow: -1px 0 2px rgba(0, 0, 0, .3)
  z-index: 2

  @include media-breakpoint-down(sm)
    margin-bottom: 49px

  .container
    display: block
    margin: 1rem auto

    .form-field
      @include media-breakpoint-up(md)
        font-size: 15px

    .form-field::-webkit-input-placeholder
      @include media-breakpoint-down(sm)
        opacity: 0

    .chat-tip
      margin-top: 5px
      color: #868e96
      font-size: 0.8rem

    .input-group
      flex-wrap: nowrap

    .button
      flex: none



.discussion-new-message-block
  &.chat-block
    flex: none
    z-index: 2
    top: 0
    bottom: 0
    width: 100%
    background-color: $app-background
    display: none
    @include media-breakpoint-down(sm)
      margin: 0 -.25rem -.25rem
      .discussion-input
        height: calc(100vh - 88px)
      .attach-document-link
        top: 50%
        right: 0

