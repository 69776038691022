@media (min-width: 1200px)
  .user-management-container
    max-width: 1140px

.manage-group-membership-table
  thead, tbody
    th, td
      border: 0

    th
      vertical-align: baseline

  .access-column-header
    text-align: center
    width: 15%
    font-weight: normal

    .select-all
      display: block
      font-size: .75rem
      white-space: nowrap

  .group-column
    width: 85%

  .access-column
    text-align: center
    width: 15%

  .checkbox
    margin-left: 15px
    margin-bottom: 0
    label
      margin-bottom: 0


.edit-user-info-modal
  .modal-content-header.stripe
    padding: 0

    .modal-stripe
      width: 100%

      .alert
        position: relative
        &.is-admin
          background-color: $account-admin-color
        &.is-guest
          background-color: $account-guest-color

.add-new-schema-users-modal
  .modal-dialog
    max-height: calc(100% - 30px * 2)
    height: auto

    .card.additional
      border-top: 1px solid #e9ecef

      label
        display: none
        @media (max-width: $break-semilarge)
          display: block

.add-account-members-modal
  .modal-dialog
    max-width: 550px

  .account-member-list
    .card.horizontal
      .card-controls
        flex-basis: 110px
        align-items: center

        .checkbox
          display: none

      &.is-selected
        .card-controls
          .checkbox
            display: block

          select
            width: 110px
            margin-right: 10px

            &.membership-type-select
              width: 150px

            &.inactive
              color: #d4d4d4 !important

          flex-basis: 175px
