.library
  flex-direction: column
  font-family: $font-primary-regular
  &-table
    border: 1px solid rgba(0, 0, 0, .1)
    border-radius: .5rem
    width: 100%
    tr
      border-bottom: 1px solid rgba(0, 0, 0, .1)
      tbody & &:last-child
        border-bottom: none
    th, td
      padding: 1rem
