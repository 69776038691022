$card-header-background-color: #44465A
$card-header-text-color: #FFF
$card-header-border-radius: 4px
$card-padding-x: 2rem
$table-header-background-color: #6C94CA
$table-header-color: #FFF
$light-grey-background-color: #a6a6a6
$dark-grey-background-color: #808080
$last-column-border: 3px dashed #FF9900

.zephyrus-reports-header
  @extend .align-horizontally-center
  position: relative
  flex: 0 0 auto
  min-height: 48px
  padding: 11px 1rem
  background-color: #4994e4
  color: white

  .container
    justify-content: space-between
    display: flex
    @include media-breakpoint-down(sm)
      flex-direction: column

  select.date-select
    cursor: pointer
    text-decoration: underline
    margin-left: 0.3rem
    margin-right: 0.3rem
    appearance: none
    background-color: #4994e4
    border-color: #4994e4
    color: white !important
    padding: 0
    outline: none

@media (min-width: 1200px)
  .zephyrus-reports-container
    max-width: 1140px

  .zephyrus-reports-header
    .container
      max-width: 1140px

.report-slides
  margin-top: 1.5rem

  .card
    margin-bottom: 0.5rem
    padding: 0

    &:not(:first-of-type)
      .card-header:first-child
        border-radius: $card-header-border-radius

    .report-chart-container
      height: 450px

      +screen(medium-down)
        margin-left: -7%
        width: 115%

      &.large
        height: 800px

    .card-header
      background-color: $card-header-background-color
      border-radius: $card-header-border-radius
      padding: 0.75rem 2.5rem 0.75rem 1.5rem
      cursor: pointer
      position: relative

      .report-subheader
        cursor: text
        max-width: max-content

      &.collapsed
        &:before
          transform: rotate(180deg)

      &:before
        border-left: 5px solid transparent
        border-right: 5px solid transparent
        border-bottom: 5px solid $card-header-text-color
        top: 50%
        content: ''
        display: inline-block
        height: 0
        width: 0
        position: absolute
        right: 1.3rem

      .report-header
        margin-bottom: 0.25rem

      .report-subheader
        color: #9e9eb0

        strong
          text-decoration: underline
          color: white

      & > *
        color: $card-header-text-color

    .card-body
      padding: 1.5rem $card-padding-x

      .report-description
        line-height: 1.2rem

      &.market-background-body
        ul
          padding-left: 0

          .list-item-title
            text-decoration: underline


.report-content-wrapper
  display: flex
  justify-content: space-between
  flex-direction: row
  flex-wrap: wrap
  overflow: auto

.report-table
  text-align: center
  font-size: 0.7em

  th
    background-color: $table-header-background-color
    color: $table-header-color

  th, td
    vertical-align: middle

  td.name
    min-width: 120px

  tr.summary-row
    font-weight: bold
    color: $table-header-color

    &.light-grey
      background-color: $light-grey-background-color

    &.dark-grey
      background-color: $dark-grey-background-color

#tp_funding_position-chart
  width: 50%
  min-width: 400px

.tp-funding-position-table
  width: 50%
  min-width: 400px
  margin-top: 0.5rem
  table-layout: fixed

  td, th
    width: 25%

  .funding-row
    th:last-child
      border: $last-column-border
      border-bottom: none

    td:last-child
      border-left: $last-column-border
      border-right: $last-column-border

    &--last
      td:last-child
        border: $last-column-border
        border-top: none

  .contributions-row
    background-color: $dark-grey-background-color
    color: $table-header-color

.performance-table
  overflow: auto
  thead
    th
      padding: .5em
      vertical-align: middle

      &[colspan]
        border-bottom: none

.risk-chart-header
  background-color: #264E83
  color: #FFF
  margin-top: 1em
  padding: .1em

.copy-report-link
  opacity: 0.75
  &:hover
    opacity: 1
