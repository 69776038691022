.group-chats-members-list, .group-members-list, .group-members-manage, .account-member-list
  border-top: 1px solid $separator-color

  .card
    margin: 0
    padding: 11px 20px

    &.is-selected
      background-color: #f2f8ff

    &.last-of-team
      &:after
        position: absolute
        content: ''
        border-bottom: 2px solid #E4E7EC
        width: 93%
        transform: translateX(-50%)
        bottom: 0
        left: 50%

    &.horizontal
      .card-controls
        display: flex
        justify-content: flex-end
        flex-basis: 65px

  .card-auxiliary
    align-items: center
    justify-content: flex-end

  .card-status-wrapper
    text-align: center
    width: 70px
    @include media-breakpoint-down(sm)
      flex-basis: 30px
      text-align: right
      width: auto

  .card-status-label
    @include media-breakpoint-down(sm)
      width: 12px
      height: 12px
      border-radius: 50%

      span
        display: none

  .checkbox
    display: inline-flex
    margin-bottom: 0

    > label
      margin-bottom: 0
      &::before
        margin-right: 0

.group-members-list
  border-top: none

.group-members-manage
  border-bottom: none

.select-all-button
  position: absolute
  right: 2.125rem
  text-transform: lowercase
  background: none
  color: $link-color
  border: none
  &:focus
    color: $link-color

.group-members
  .group-members-account-title
    padding-top: 1rem

  .group-members-list + .group-members-account-title
    padding-top: 2.5rem

.manage-users-form
  .group-members-account-title
    padding: 0.5rem 1rem

  .group-members-manage + .group-members-account-title
      border-top: 1px solid $separator-color
      padding-top: 2.5rem

.group-member-title
  border-top: 1px solid $separator-color
  font-size: 0.875rem
  text-transform: uppercase
  position: relative
