.ptn, .pvn, .pan
  padding-top: 0

.ptx, .pvx, .pax
  padding-top: $spaceXSmall

.prx, .phx, .pax
  padding-right: $spaceXSmall

.pbx, .pvx, .pax
  padding-bottom: $spaceXSmall

.plx, .phx, .pax
  padding-left: $spaceXSmall

.mtx, .mvx, .max
  margin-top: $spaceXSmall

.mrx, .mhx, .max
  margin-right: $spaceXSmall

.mbx, .mvx, .max
  margin-bottom: $spaceXSmall

.mbm, .mvm, .mam
  margin-bottom: $spaceMedium

.mlx, .mhx, .max
  margin-left: $spaceXSmall

.ptxs, .pvxs, .paxs
  padding-top: $spaceXSmall

.pts, .pvs, .pas
  padding-top: $spaceSmall

.ptm, .pvm, .pam
  padding-top: $spaceMedium

.ptl, .pvl, .pal
  padding-top: $spaceLarge

.ptxl, .pvxl, .paxl
  padding-top: $spaceXLarge

.prn, .phn, .pan
  padding-right: 0

.prs, .phs, .pas
  padding-right: $spaceSmall

.prm, .phm, .pam
  padding-right: $spaceMedium

.prl, .phl, .pal
  padding-right: $spaceLarge

.prxl, .phxl, .paxl
  padding-right: $spaceXLarge

.pbn, .pvn, .pan
  padding-bottom: 0

.pbs, .pvs, .pas
  padding-bottom: $spaceSmall

.pbm, .pvm, .pam
  padding-bottom: $spaceMedium

.pbl, .pvl, .pal
  padding-bottom: $spaceLarge

.pbxl, .pvxl, .paxl
  padding-bottom: $spaceXLarge

.pln, .phn, .pan
  padding-left: 0

.pls, .phs, .pas
  padding-left: $spaceSmall

.plm, .phm, .pam
  padding-left: $spaceMedium

.pll, .phl, .pal
  padding-left: $spaceLarge

.plxl, .phxl, .paxl
  padding-left: $spaceXLarge

.mtn, .mvn, .man
  margin-top: 0

.mts, .mvs, .mas
  margin-top: $spaceSmall

.mtm, .mvm, .mam
  margin-top: $spaceMedium

.mtl, .mvl, .mal
  margin-top: $spaceLarge

.mtxl, .mvxl, .maxl
  margin-top: $spaceXLarge

.mrn, .mhn, .man
  margin-right: 0

.mrs, .mhs, .mas
  margin-right: $spaceSmall

.mrm, .mhm, .mam
  margin-right: $spaceMedium

.mrl, .mhl, .mal
  margin-right: $spaceLarge

.mrxl, .mhxl, .maxl
  margin-right: $spaceXLarge

.mbn, .mvn, .man
  margin-bottom: 0

.mbs, .mvs, .mas
  margin-bottom: $spaceSmall

.mbm, .mvm, .mam
  margin-bottom: $spaceMedium

.mbl, .mvl, .mal
  margin-bottom: $spaceLarge

.mbxl, .mvxl, .maxl
  margin-bottom: $spaceXLarge

.mln, .mhn, .man
  margin-left: 0

.mls, .mhs, .mas
  margin-left: $spaceSmall

.mlm, .mhm, .mam
  margin-left: $spaceMedium

.mll, .mhl, .mal
  margin-left: $spaceLarge

.mlxl, .mhxl, .maxl
  margin-left: $spaceXLarge
