.button, button
  background-color: $link-color
  color: white
  cursor: pointer
  display: inline-flex
  align-items: center
  &:disabled, &.disabled
    cursor: not-allowed
  &:focus
    box-shadow: none
    color: white
  [class^="icon-"], [class*=" icon-"]
    color: inherit
    vertical-align: text-bottom
    + span
      margin-left: .5rem
  span
    + [class^="icon-"], [class*=" icon-"]
      margin-left: .5rem
  .button-caption
    @include media-breakpoint-down(sm)
      display: none
    +
      [class^="icon-"], [class*=" icon-"]
        @include media-breakpoint-down(sm)
          margin-left: 0
  &.is-round-micro
    width: 20px
    height: 20px
    text-align: center
    padding: 1px 0
    font-size: 13px
    line-height: 1.0
    border-radius: 30px
  &.is-tiny
    padding: 3px 3px 2px
    font-size: 75%
  &.is-secondary
    background-color: transparent
    color: $link-color
    border-color: $link-color
    &:hover
      color: $link-hover-color
      border-color: $link-hover-color
      background-color: transparent
    &:not([disabled]):not(.disabled)
      &:active
        background-color: white
        color: $link-active-color
        border-color: $link-active-color
  &.is-dim
    background-color: transparent
    color: $text-muted-color
    border-color: #c4c4c4
    &:hover,
    &:active,
    &:not([disabled]):not(.disabled):active
      color: rgba($text-muted-color, 0.6)
      border-color: rgba(#c4c4c4, 0.6)
      background-color: transparent
    &:focus
      box-shadow: none !important
    .show &
      background-color: transparent
      color: $text-muted-color
      border-color: #c4c4c4

  &.rotatable
    transform: rotate(0deg)
    transition: transform 0.3s linear

    &.flipped
      transform: rotate(45deg)
      transition: transform 0.3s linear
  & + &
    margin-left: 1rem
  &.close
    outline: none

  &.is-transformer
    @include media-breakpoint-down(sm)
      padding:
        left: 0.38rem
        right: 0.38rem
      min-width: 35px
      justify-content: center
    @include media-breakpoint-up(md)
      [class^="icon-"], [class*=" icon-"]
        display: none
      .button-caption
        margin-left: 0

  &.has-icon
    @include media-breakpoint-down(sm)
      padding:
        left: 0.38rem
        right: 0.38rem
      min-width: 35px
      justify-content: center

  &.is-success-light
    @extend .is-success

    background-color: $success-color
    border-color: $success-color

    &:hover
      background-color: darken($success-color, 10%)
      border-color: darken($success-color, 10%)

    &:focus
      box-shadow: 0 0 0 0.2rem rgba(darken($success-color, 10%), .5)

  &.two-factor-switch
    @extend .button

    font-size: 112.5%

  &.manage-users-small
    margin-top: 0.5rem
    padding: 0.375rem 0.375rem
