.security-copy
  display: flex
  margin-top: 20px
  color: $text-muted
  font-size: 15px

  &-text
    flex: 1 1 auto

  &-icon
    flex: none
    margin-right: 10px
    padding-top: 3px
