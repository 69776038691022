@font-face
  font-family: 'Space Grotesk'
  src: url("../../fonts/SpaceGrotesk/SpaceGrotesk-Light.ttf") format("truetype")
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Space Grotesk'
  src: url("../../fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf") format("truetype")
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Space Grotesk'
  src: url("../../fonts/SpaceGrotesk/SpaceGrotesk-Medium.ttf") format("truetype")
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Space Grotesk'
  src: url("../../fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.ttf") format("truetype")
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Space Grotesk'
  src: url("../../fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf") format("truetype")
  font-weight: 700
  font-style: normal
